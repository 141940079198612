import * as React from "react";
import IconButton from "@mui/material/IconButton";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type MenuProps = {
  options: string[];
  onClick: (item: string) => void;
};

export const Menu: React.FC<MenuProps> = ({ options, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnItemClick = (item: string) => {
    handleClose();
    onClick(item);
  };

  return (
    <div>
      <IconButton id="long-button" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <MuiMenu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleOnItemClick(option)}>
            {option}
          </MenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};
