export type Route = {
  label: string;
  path: string;
};

export type Paths = {
  home: Route;
  login: Route;
  register: Route;
  forgetPassword: Route;
  auth: Route;
  profil: Route;
  changePassword: Route;
  admin: Route;
  users: Route;
  videos: Route;
  addVideos: Route;
  watch: Route;
  listByCategory: Route;
  search: Route;
  contact: Route;
  messages: Route;
  teams: Route;
  teamsForms: Route;
  categories: Route;
  addArticle: Route;
  articles: Route;
};

export const paths = {
  home: {
    label: "Accueil",
    path: "/",
  },
  login: {
    label: "Login",
    path: "/login",
  },
  register: {
    label: "Register",
    path: "/register",
  },
  forgetPassword: {
    label: "Forget Password",
    path: "/forget-password",
  },
  auth: {
    label: "Auth",
    path: "/auth",
  },
  profil: {
    label: "Profil",
    path: "/profil",
  },
  changePassword: {
    label: "Password",
    path: "/profil/password",
  },
  admin: {
    label: "admin",
    path: "/admin",
  },
  users: {
    label: "Utilisateurs",
    path: "/admin/users",
  },
  videos: {
    label: "Videos",
    path: "/admin/videos",
  },
  addVideos: {
    label: "Nouvelle vidéo",
    path: "/admin/videos/new",
  },
  categories: {
    label: "Tags",
    path: "/admin/tags",
  },
  addCategories: {
    label: "Nouveau tag",
    path: "/admin/tags/new",
  },
  messages: {
    label: "Messages",
    path: "/admin/messages",
  },
  watch: {
    label: "Watch",
    path: "/watch/:videoId",
  },
  listByCategory: {
    label: "Lister par catégorie",
    path: "/:categoryId/:categoryName",
  },
  search: {
    label: "Recherche",
    path: "/search",
  },
  contact: {
    label: "contact",
    path: "/contact",
  },
  teams: {
    label: "Equipes",
    path: "/competition/:competition/equipes",
  },
  addTeam: {
    label: "Créer une équipe",
    path: "/admin/equipes/new",
  },
  editTeam: {
    label: "editer une quipe",
    path: "/admin/equipes/:teamId",
  },
  detailTeam: {
    label: "editer une quipe",
    path: "/competition/:competition/equipes/:teamId",
  },
  groups: {
    label: "Groupes",
    path: "/admin/competitions/:competitionId/groups",
  },
  addGroup: {
    label: "Créer un groupe",
    path: "/admin/competitions/:competitionId/groups/new",
  },
  editGroup: {
    label: "Créer un groupe",
    path: "/admin/competitions/:competitionId/groups/:groupId",
  },
  matchs: {
    label: "Matchs",
    path: "/competition/:competition/matchs",
  },
  addMatch: {
    label: "Créer un match",
    path: "/admin/matchs/new",
  },
  editMatch: {
    label: "Editer un match",
    path: "/admin/matchs/:matchId",
  },
  adminCompetitions: {
    label: "Compétitions",
    path: "/admin/competitions",
  },
  addCompetition: {
    label: "Créer une compétition",
    path: "/admin/competitions/new",
  },
  editCompetition: {
    label: "Editer une  Compétition",
    path: "/admin/competitions/:competitionId",
  },
  ranking: {
    label: "Classement",
    path: "/competition/:competition/classement",
  },
  topScorer: {
    label: "Buteurs",
    path: "/competition/:competition/buteurs",
  },
  addArticle: {
    label: "Créer un article",
    path: "/admin/articles/new",
  },
  editArticle: {
    label: "Editer un article",
    path: "/admin/articles/:articleId",
  },
  articles: {
    label: "Articles",
    path: "/admin/articles",
  },
  viewArticle: {
    label: "articles",
    path: "/articles/:articleId/:slug",
  },
  affiche: {
    label: "Affiche",
    path: "/admin/publications/affiche",
  },
  publications: {
    label: "Publications",
    path: "/admin/publications",
  },
  competitions: {
    label: "Actualités",
    path: "/competition/:competition",
  },
  live: {
    label: "en direct",
    path: "/directs",
  },
  liveMatch: {
    label: "Le match",
    path: "/directs/:id",
  },
  liveMatchLineUp: {
    label: "Composition",
    path: "/directs/:id/composition",
  },
  liveMatchStats: {
    label: "Statistiques",
    path: "/directs/:id/stats",
  },
  liveMatchInfos: {
    label: "Infos",
    path: "/directs/:id/infos",
  },
  myList: {
    label: "Ma liste",
    path: "/ma-liste",
  },
};

export const getRoundInFrench = (name: string) => {
  return name
    ?.replace("Regular Season", "Journée")
    ?.replace("Group Stage", "Phase de groupe")
    ?.replace("Round of 16", "Huitieme de finale")
    .replace("Quarter-finals", "Quart de finale")
    .replace("Semi-finals", "Demi finale")
    .replace("3rd Place Final", "3e Place")
    .replace("Final", "Finale");
};

const stats = {
  "Shots on Goal": "Tirs cadrés",
  "Shots off Goal": "Tirs non cadrés",
  "Total Shots": "Tirs Total",
  "Blocked Shots": "Tirs bloqués",
  "Shots insidebox": "Tirs dans la surface",
  "Shots outsidebox": "Tirs de loin",
  Fouls: "Fautes",
  "Corner Kicks": "Corners",
  Offsides: "Hors-jeu",
  "Ball Possession": "Possession",
  "Yellow Cards": "Cartons jaunes",
  "Red Cards": "Cartons rouges",
  "Goalkeeper Saves": "Arrêts",
  "Total passes": "Total passes",
  "Passes accurate": "Passes réussies",
};

export const getStatsInFrench = (name: string) => {
  return stats[name] ?? name;
  //return name?.re
};
