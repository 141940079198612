import React from "react";
import MUIRichTextEditor, {
  TMUIRichTextEditorRef,
  TMUIRichTextEditorProps,
} from "mui-rte/dist/MUIRichTextEditor";
import { convertToRaw } from "draft-js";
import { useField } from "formik";
import {
  createTheme as createMuiTheme,
  ThemeProvider,
} from "@mui/material/styles";
import PreviewIcon from "@mui/icons-material/Preview";
import SportsFootball from "@mui/icons-material/SportsSoccer";
import InsertIframe, { IframeView } from "./insert-iframe";
import InsertMatchsViewer, { InsertMatchs } from "./insert-matchs";
import { useState } from "react";
import { useRef } from "react";
const createTheme = (darkMode: boolean, readOnly = false) =>
  createMuiTheme({
    components: {
      palette: {
        mode: "dark",
      },
      MUIRichTextEditor: {
        styleOverrides: {
          root: {
            marginTop: 2,
            width: "100%",
            minHeight: readOnly ? 0 : "150px",
            marginBottom: 20,
            border: readOnly ? "none" : "1px solid gray",
            paddingBottom: readOnly ? 0 : 16,
            fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
          },
          toolbar: {
            borderBottom: readOnly ? "none" : "1px solid gray",
          },
          editor: {
            //padding: "16px",
          },
          editorContainer: readOnly
            ? {}
            : {
                paddingLeft: "16px",
                paddingRight: "16px",
              },
        },
      },
    },
  } as any);

type RteProps = TMUIRichTextEditorProps & {
  value: string;
  label: string;
};

export const Rte: React.FC<RteProps> = ({
  value,
  onChange,
  label,
  readOnly,
}) => {
  const ref = useRef<TMUIRichTextEditorRef>(null);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [defaultValue] = useState(value);

  return (
    <ThemeProvider theme={createTheme(true, readOnly)}>
      <InsertIframe
        anchor={anchor}
        onSubmit={(data, insert) => {
          console.log("dattaaaa", data);
          if (insert && data.text) {
            console.log("insert iframe view");
            ref.current?.insertAtomicBlockSync("iframe-viewer", data);
          }
          setAnchor(null);
        }}
      />
      <InsertMatchs
        open={open}
        close={handleClose}
        onSubmit={(data) => {
          handleClose();
          ref.current?.insertAtomicBlockSync("matchs-viewer", data);
        }}
      />
      <MUIRichTextEditor
        ref={ref}
        toolbar={!readOnly}
        defaultValue={defaultValue}
        label={label}
        onChange={onChange}
        controls={[
          "title",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "highlight",
          "undo",
          "redo",
          "link",
          "media",
          "numberList",
          "bulletList",
          "quote",
          "iframe",
          "matchs",
        ]}
        customControls={[
          {
            name: "iframe",
            icon: <PreviewIcon />,
            type: "callback",
            onClick: (_editorState, _name, anchor) => {
              setAnchor(anchor);
            },
          },
          {
            name: "matchs",
            icon: <SportsFootball />,
            type: "callback",
            onClick: (_editorState, _name, anchor) => {
              handleClickOpen();
            },
          },
          {
            name: "iframe-viewer",
            type: "atomic",
            atomicComponent: IframeView,
          },
          {
            name: "matchs-viewer",
            type: "atomic",
            atomicComponent: InsertMatchsViewer,
          },
        ]}
        readOnly={readOnly}
      />
    </ThemeProvider>
  );
};

export const RteField = (props: any) => {
  const [{ value }, {}, { setValue }] = useField(props.name);

  const handleOnChange = (event) => {
    const rteContent = convertToRaw(event.getCurrentContent()); // for rte content with text formating
    console.log("i am changed", rteContent);
    setValue(JSON.stringify(rteContent));
  };
  return (
    <Rte
      value={value}
      label={props.label}
      onChange={handleOnChange}
      readOnly={false}
    />
  );
};

export default RteField;
