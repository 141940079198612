import React from "react";
import { Box, Typography } from "@mui/material";
import { MatchEvent } from "types";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";

type ScorersProps = {
  events: MatchEvent[];
  teamName: string;
  alignement: "start" | "end";
};

export const MatchScorers: React.FC<ScorersProps> = ({
  events,
  alignement,
  teamName,
}) => {
  const scorers = events?.filter(
    (event) =>
      event.type === "Goal" &&
      event.team.name === teamName &&
      event.comments !== "Penalty Shootout"
  );

  return (
    <Box>
      {scorers.map((scorer, index) => (
        <Typography
          key={`${scorer.player.id} - ${scorer.time.elapsed}`}
          variant="body2"
          color="text.secondary"
          textAlign="center"
          sx={{
            width: "auto",
            marginTop: index === 0 ? 2 : 0,
            display: "flex",
            flexDirection: alignement === "end" ? "row" : "row-reverse",
            gap: 1,
            alignSelf: `flex-${alignement}`,
          }}
          component="div"
          gutterBottom
        >
          <SportsSoccerIcon fontSize="small" color="inherit" />
          <strong>{scorer.time.elapsed}' </strong> {scorer.player.name}
        </Typography>
      ))}
    </Box>
  );
};
