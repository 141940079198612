import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { TeamName } from "./team-name";

import { Team as TeamType } from "../../types";

type TeamProps = TeamType & {
  onClick?: (id?: string) => void;
};

export const Team: React.FC<TeamProps> = ({ id, logo, name, onClick }) => {
  return (
    <Card>
      <CardActionArea onClick={() => onClick?.(id)}>
        <CardMedia
          component="img"
          height="300"
          image={logo}
          alt="green iguana"
        ></CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
