import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Match as MatchType } from "types";
import { Match } from "./match";
import { useRoutes, useStandingsQueries } from "../../hooks";
import { GROUPS } from "../../constants";

type TeamListProps = {
  matchs: MatchType[];
  round?: string;
  subtitle?: string;
  published?: boolean;
  isCup?: boolean;
  useProxy?: boolean; 
  onClick?: (id?: string) => void;
};

export const MatchList: React.FC<TeamListProps> = ({
  matchs,
  subtitle,
  published,
  isCup, 
  useProxy
}) => {
const league = matchs?.at(0)?.league
  const { data } = useStandingsQueries(
    { league: league?.id!, season: league?.season! },
    isCup
  );
  const { paths, navigate } = useRoutes();
  let lastIndex = -1; 

  const handleOnClick = (id: number) => {
    navigate(paths.liveMatch.path.replace(":id", id.toString()))();
  };

  const renderGroup = (teamId: string, round: string)=> {
    const index = data?.findIndex(standing => standing.find(team => team.team.id === teamId))
    if(lastIndex === index || index === undefined || !round?.includes('Group Stage')){
      return null 
    }
    lastIndex = index; 
    return <Typography sx={{marginBottom: 1}} variant={published ? 'h6': "subtitle1"}>{GROUPS[index]}</Typography>
  }

  return (
    <Box>
      {subtitle && (
        <Typography
          gutterBottom
          variant={published ? "h4" : "h6"}
          sx={{ textTransform: "capitalize" }}
        >
          {subtitle}
        </Typography>
      )}
      <div>
        {matchs.map((match) => (
          <Box key={match.fixture.id} sx={{ marginBottom: 3 }}>
            {isCup && renderGroup(match.teams.home.id!, match.league.round)}
            <Match
              {...match}
              published={published}
              isCup={isCup}
              useProxy={useProxy}
              onClick={() => handleOnClick(match.fixture.id)}
            />
          </Box>
        ))}
      </div>
    </Box>
  );
};
