import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Container from "@mui/material/Container";
import { paths } from "../../routes";
import { Header } from "../../components";
import { ViewArticle } from "../home";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useRoutes } from "../../hooks";
import { Toolbar } from "@mui/material";

import loadable from "@loadable/component";
import MyList from "../../pages/my-list/MyList";
import Home from '../home/home'

const Profil = loadable(() => import("../profil/profil"));

export const Main = () => {
  const { currentPath } = useRoutes();

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const analytics = getAnalytics();
      logEvent(analytics, "pages", {
        firebase_screen: currentPath?.path,
      });
    }
  }, [currentPath]);

  return (
    <div>
      <Header />
      <Container maxWidth="md" sx={{ minHeight: "100Vh", padding: 2 }}>
        <Toolbar />
        <Routes>
          <Route path={paths.profil.path + "/*"} element={<Profil />} />
          <Route path={paths.viewArticle.path} element={<ViewArticle />} />
          <Route path={paths.myList.path} element={<MyList />} />
          <Route path={paths.home.path} element={<Home />} />
        </Routes>
      </Container>
    </div>
  );
};
