import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
} from "@mui/material";
import BookMark from "@mui/icons-material/Bookmark";
import { useRoutes } from "../hooks";
import Like from "@mui/icons-material/ThumbUpAlt";
import Comment from "@mui/icons-material/Comment";

export enum PreventAuthModalTypes {
  like = "like",
  bookmark = "bookmark",
  comment=  "comment",
};

const types = {
  [PreventAuthModalTypes.like]: {
    icon: <Like sx={{ fontSize: "128px" }} />,
    title: "Aimer la publication",
    text: "Pour aimer un contenu à votre liste, rejoignez notre communauté.",
  },
  [PreventAuthModalTypes.bookmark]: {
    icon: <BookMark sx={{ fontSize: "128px" }} />,
    title: "Ajouter à ma liste",
    text: "Pour ajouter un contenu à votre liste, rejoignez notre communauté.",
  },
  [PreventAuthModalTypes.comment]: {
    icon: <Comment sx={{ fontSize: "128px" }} />,
    title: "Commenter la publication",
    text:
      "Pour commenter un contenu à votre liste, rejoignez notre communauté.",
  },
};

export interface PreventAuthModalProps {
  open: boolean;
  type?: PreventAuthModalTypes;
  onClose: () => void;
}

export const PreventAuthModal: React.FC<PreventAuthModalProps> = ({
  type,
  open,
  onClose,
}) => {
  const { paths, navigate } = useRoutes();
  const login = navigate(paths.login.path);
  const register = navigate(paths.register.path);
  const modalType = type && types[type];

  return (
    <Dialog onClose={onClose} open={open} sx={{ padding: 2 }}>
      <DialogTitle sx={{ textAlign: "center" }}>{modalType?.title}</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        {modalType?.icon}
        <Typography>{modalType?.text}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            marginTop: 3,
          }}
        >
          <Button variant="contained" onClick={register}>
            Créer un compte
          </Button>
          <Button color="inherit" onClick={login}>
            Se connecter
          </Button>
          <Button color="info" onClick={onClose}>
            Fermer
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
