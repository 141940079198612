import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CommentAvatar from "./comment-avatar";
import CommentInput from "./comment-input";
import { Comment } from "../../types";
import { Menu } from "../../components/menu";

type CommentProps = {
  author?: string;
  comment: Comment;
  parentId?: string;
  canEdit?: boolean;
  onReply: (text: string, parentId?: string) => void;
  onEdit: (comment) => void;
  onDelete: (commentId: Comment["id"]) => void;
};
const CommentItem: React.FC<CommentProps> = ({
  author,
  comment,
  parentId,
  canEdit,
  children,
  onReply,
  onEdit,
  onDelete,
}) => {
  const [reply, setReply] = useState({
    visible: false,
    value: "",
    editMode: false,
  });

  const openReply = () => {
    setReply({ visible: true, value: "", editMode: false });
  };

  const closeReply = () => {
    setReply({ visible: false, value: "", editMode: false });
  };

  const handleOnClik = (item: string) => {
    if (item === "Editer") {
      return setReply({ visible: true, value: comment.text, editMode: true });
    }
    onDelete(comment.id);
  };

  const handleOnSubmit = (text) => {
    if (reply.editMode) {
      return onEdit({ ...comment, text });
    }
    onReply(text, parentId);
  };

  return (
    <Box sx={{ display: "flex", gap: 2, paddingY: 2 }}>
      <CommentAvatar
        name={`${comment.author?.firstName} ${comment.author?.lastName}`}
      />
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography>
              {comment.author?.firstName} {comment.author?.lastName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {comment.text}
            </Typography>
          </div>

          {canEdit && (
            <Menu options={["Editer", "Supprimer"]} onClick={handleOnClik} />
          )}
        </Box>
        {reply.visible ? (
          <CommentInput
            author={author}
            defaultValue={reply.value}
            onSubmit={handleOnSubmit}
            onCancel={closeReply}
            focus
          />
        ) : (
          <Button onClick={openReply}>Repondre</Button>
        )}
        {children}
      </Box>
    </Box>
  );
};
export default CommentItem;
