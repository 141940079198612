import React from "react";
import * as Yup from "yup";
import { DynamicForm, SubmitButton, Layout, AuthContainer } from "../../components";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useRoutes, useCrud } from "../../hooks";

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  resetPassword: {
    marginTop: theme.spacing(3),
  },
  action: {
    flex: 1,
  },
  secondBtn: {
    width: `100%`,
    marginTop: theme.spacing(3),
  },
  info: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
}));

export const Contact = () => {
  const classes = useStyles();
  const { paths, navigate } = useRoutes();
  const { create } = useCrud("messages");
  const navigateToHome = navigate(paths.home.path);
  const handleOnSubmit = React.useCallback(async (values) => {
    try {
      await create(values);
    } catch (error) {
      console.log("error");
    }
  }, [create]);
  return (
    <Layout>
      <AuthContainer title="Nous contacter">
        <DynamicForm
          fields={{
            firstName: {
              label: "Prénom",
              type: "text",
            },
            lastName: {
              label: "Nom",
              type: "text",
            },
            email: {
              label: "Email",
              type: "text",
            },
            message: {
              label: "Message",
              type: "text",
              multiline: true,
              rows: 8,
            },
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("ce champ est requis"),
            lastName: Yup.string().required("Ce champ est requis"),
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
            message: Yup.string().required("ce champ est requis"),
          })}
          onSubmit={handleOnSubmit}
        >
          {(form: any) => (
            <SubmitButton
              color="primary"
              title="Envoyer"
              variant="contained"
              className={classes.action}
              disabled={!form.isValid || form.isSubmitting}
              isSubmitting={form.isSubmitting}
            />
          )}
        </DynamicForm>
        <Button
          color="primary"
          className={classes.secondBtn}
          onClick={navigateToHome}
        >
          Aller à la page d'accueil
        </Button>
      </AuthContainer>
    </Layout>
  );
};

export default Contact;
