import * as Yup from "yup";
import React, { useCallback, useState } from "react";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { DynamicForm, Social, AuthContainer, Layout } from "../../components";
import { useAuthApi, useRoutes } from "../../hooks";

const ResetLink = styled(Link)(({theme}) => ({
  cursor: "pointer",
  marginTop: theme.spacing(3),
}));

export const Login: React.FC = () => {
  const { paths, navigate } = useRoutes();
  const navigateToRegister = navigate(paths.register.path);
  const navigateToHome = navigate(paths.home.path);
  const navigateToForgetPassword = navigate(paths.forgetPassword.path);

  const { login } = useAuthApi();
  const [error, setError] = useState("");

  const handleOnSubmit = useCallback(
    async ({ email, password }) => {
      try {
        setError("");
        await login(email, password);
        navigateToHome();
      } catch (error) {
        setError("auth/wrong-credentials");
      }
    },
    [setError, login, navigateToHome]
  );

  return (
    <Layout>
      <AuthContainer title="Connexion">
        <DynamicForm
          header={<Social onSuccess={navigateToHome} onError={setError} />}
          fields={{
            email: {
              label: "Email",
              type: "text",
            },
            password: {
              label: "Mot de passe",
              type: "password",
            },
            reset: {
              label: "Mot de passe oublié",
              type: "custom",
              component: (
                <ResetLink onClick={navigateToForgetPassword}>
                  Mot de passe oublié ?{" "}
                </ResetLink>
              ),
            },
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
            password: Yup.string().required("Ce champ est requis"),
          })}
          error={error}
          errorType="error"
          cancelTitle="Créer un nouveau compte"
          submitTitle="Se connecter"
          onSubmit={handleOnSubmit}
          onCancel={navigateToRegister}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Login;
