import { TeamList } from "../../components";
import { useSelectedCompetition, useTeamsQueries } from "../../hooks";
import { useRoutes } from "../../hooks";
import { useParams } from "react-router-dom";

export const Teams = () => {
  const competition = useSelectedCompetition();
  const { data: teams } = useTeamsQueries(
    { league: competition?.competitionId!, season: competition?.season! },
    !!competition
  );
  const { paths, navigate } = useRoutes();
  const params = useParams<{ competition?: string }>();

  const handleOnClick = (teamId) => {
    navigate(
      paths.detailTeam.path
        .replace(":competition", params?.competition ?? "")
        .replace(":teamId", teamId)
    )();
  };

  if (!teams) return null;
  return <TeamList teams={teams} onClick={handleOnClick} />;
};
