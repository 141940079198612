import React from "react";
import { Route, Routes } from "react-router-dom";
import { Add } from "./add";
import { Edit } from "./edit";
import { BaseFormsProps } from "./forms";
import List, { ListProps } from "./list";

type CrudProps = BaseFormsProps & Omit<ListProps, "name">;

export const Crud: React.FC<CrudProps> = ({
  name,
  initialValues,
  fields,
  validationSchema,
  columns,
  transform,
}) => (
  <Routes>
    <Route
      path="/new"
      element={
        <Add
          name={name}
          initialValues={initialValues}
          fields={fields}
          validationSchema={validationSchema}
          transform={transform}
        />
      }
    />
    <Route
      path="/:id"
      element={
        <Edit
          name={name}
          fields={fields}
          validationSchema={validationSchema}
          transform={transform}
        />
      }
    />
    <Route path="/" element={<List name={name} columns={columns} />} />
  </Routes>
);

export default Crud;
