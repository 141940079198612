import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import {
  MatchCompetitionRound,
  CompetitionRoundParams,
} from "../../components/matchs";
import { useMatchsQueries } from "../../hooks";
import { Match } from "types";

export type SelectMatchParams = CompetitionRoundParams & { matchs: Match[]; }

type InsertMatchProps = {
  selecteds: SelectMatchParams;
  onChange: (select: SelectMatchParams) => void;
};

export const MatchSelect: React.FC<InsertMatchProps> = ({
  selecteds,
  onChange,
}) => {
  const [params, setParams] = useState<CompetitionRoundParams>();

  const { data = [] } = useMatchsQueries({
    round: params?.round,
    league: params?.competition?.competitionId,
    season: params?.competition?.season,
  });

  const handleSelectMatch = (selectedMatch: Match) => {
    const checked = !!selecteds.matchs.find(
      (match) => match.fixture.id === selectedMatch.fixture.id
    );

    if (checked) {
      return onChange({
        ...selecteds,
        competition: params?.competition,
        round: params?.round ?? "",
        matchs: selecteds.matchs.filter(
          (match) => match.fixture.id !== selectedMatch.fixture.id
        )
      }
      );
    }
    onChange({
      ...selecteds,
      competition: params?.competition,
      round: params?.round ?? "",
      matchs: [...selecteds.matchs, selectedMatch]
    });
  };
  return (
    <div>
      <MatchCompetitionRound onChange={setParams} />
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Sélectionner les matchs</FormLabel>
        <FormGroup>
          {data.map((match, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={
                    !!selecteds.matchs.find((m) => m.fixture.id === match.fixture.id)
                  }
                  onChange={() => handleSelectMatch(match)}
                />
              }
              label={`${match.teams.home.name} - ${match.teams.away.name} `}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};
