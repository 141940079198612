import React from "react";
import Root from "./root";
import "./init";
import { DarkModeProvider } from "./components";
import { AppProvider } from "./context/app/AppContext";

//const db = getFirestore();
//connectFirestoreEmulator(db, 'localhost', 8080);

function App() {
  return (
    <div className="App">
      <DarkModeProvider>
        <AppProvider>
          <Root />
        </AppProvider>
      </DarkModeProvider>
    </div>
  );
}

export default App;
