import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { PlayerScorer } from "../../types";
import {  TeamLogo } from "../teams";

function Row(props: { row: PlayerScorer, isCup?:boolean }) {
  const { row, isCup } = props;
  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell component="th" scope="row">
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: 50,
            alignItems: "flex-start",
          }}
        >
          <Box sx={{marginTop: "4px"}}>

          <TeamLogo
            alt={row.statistics.team.name}
            logo={row.statistics.team.logo ?? ''}
            horizontalRatio={isCup}
          />
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="body2">{row.player.name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {" "}
              {row.statistics.team.name}
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ marginLeft: 2 }} variant="body2"></Typography>
      </TableCell>
      <TableCell align="right">{row.statistics.goals.total}</TableCell>
      <TableCell align="right">{row.statistics.games.appearences}</TableCell>
    </TableRow>
  );
}

type PlayersScorersProps = {
  players: PlayerScorer[];
  isCup?: boolean;
};

export const PlayersScorers: React.FC<PlayersScorersProps> = ({ players, isCup }) => {
  return (
    <TableContainer component={"div"}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="right">Buts</TableCell>
            <TableCell align="right">Matchs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {players.map((player) => (
            <Row key={player.player.id} row={player} isCup={isCup} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
