import React, { useCallback, useState } from "react";
import {  makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/IconButton";
import { useRoutes } from "../../hooks/routes";

const useStyles = makeStyles((theme: any) => ({
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
   // backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
     // backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: 600,
    },
  },
  searchIcon: {
    height: "100%",
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  hideClose: {
    display: "none",
  },
}));

export const Search = () => {
  const classes = useStyles();
  const { paths, navigate } = useRoutes();
  const [query, setQuery] = useState("");

  const handleOnChange = useCallback(
    (e) => {
      const value = e.target.value;
      setQuery(e.target.value);
      if (value) {
        navigate(`${paths.search.path}?query=${value}`)();
      } else {
        navigate(paths.home.path)();
      }
    },
    [navigate, paths]
  );

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        value={query}
        placeholder="Rechercher"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={handleOnChange}
      />
      {!!query && (
        <div className={classes.searchIcon}>
          <Button
            size="small"
            color="inherit"
            onClick={() => handleOnChange({ target: { value: "" } })}
          >
            <CloseIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Search;
