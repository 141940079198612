import React, { useState, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { AdminHeader, AdminSidebar, BreadCrumb, Crud } from "../../components";
import Typography from "@mui/material/Typography";
import { useRoutes } from "../../hooks";
import loadable from "@loadable/component";


import CompetitionsCrud from "./competitionsCrud";
import ArticlesCrud from "./articlesCrud";
import TagsCrud from "./tagsCrud";
const Publication = loadable(() => import("../../pages/publication/publication"));
const PublicationAffiche = loadable(() => import("../../pages/publication/publication-result"));

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    minHeight: "100vh",
  },
  toolbar: theme.mixins.toolbar,
  container: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    minHeight: 100,
    background: theme.palette.background.paper,
    margin: `${theme.spacing(2)}px 0`,
    padding: theme.spacing(2),
  },
  infos: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  title: {
    color: theme.palette.text.primary,
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
}));

export const Admin = () => {
  const classes = useStyles();
  const [mobileOpened, setMobileOpen] = useState(false);
  const { currentPath } = useRoutes();

  const handleMobileSidebarToggle = useCallback(() => {
    setMobileOpen((opened) => !opened);
  }, [setMobileOpen]);
  return (
    <div className={classes.root}>
      <AdminHeader enableMenu onMenuClick={handleMobileSidebarToggle} />
      <AdminSidebar
        mobileOpened={mobileOpened}
        onClose={handleMobileSidebarToggle}
      />
      <main className={classes.container}>
        <div className={classes.toolbar} />
        <div className={classes.infos}>
          <div className={classes.left}>
            <Typography variant="h5" className={classes.title}>
              {currentPath?.label || "Détail"}
            </Typography>
          </div>
          <BreadCrumb />
        </div>
        <div className={classes.content}>
          <Routes>
            <Route path={"/articles/*"} element={<ArticlesCrud />} />
            <Route path={"/tags/*"} element={<TagsCrud />} />
            <Route path="/competitions/*" element={<CompetitionsCrud />} />
            <Route path={"/publications"} element={<Publication />} />
            <Route
              path={"/publications/affiche"}
              element={<PublicationAffiche />}
            />
            <Route path={"/"} element={<div>ouloulou</div>} />
          </Routes>
        </div>
      </main>
    </div>
  );
};

export default Admin;
