import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Container } from "@mui/material";
import { MatchList, MatchSelect, SelectMatchParams } from "../../components/matchs";
import { useMatchsQueries } from "../../hooks";
import { orderBy, groupBy } from "lodash";

type InsertMatchProps = {
  open: boolean;
  onSubmit: (selected: string) => void;
  close: () => void;
};

export const InsertMatchs: React.FC<InsertMatchProps> = ({
  open,
  onSubmit,
  close,
}) => {
  const [selectedMatchs, setSelectedMatchs] = useState<SelectMatchParams>({
    round: '', 
    competition: undefined, 
    matchs: []
  });

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Ajouter des matchs</DialogTitle>
        <DialogContent>
          <Container maxWidth="md">
            <MatchSelect
              selecteds={selectedMatchs}
              onChange={setSelectedMatchs}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            onClick={() =>
              onSubmit(JSON.stringify(selectedMatchs.matchs.map((m) => m.fixture.id)))
            }
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default (props) => {
  const { blockProps } = props;
  const ids = (JSON.parse(blockProps) as number[]).join("-");
  const { data = [] } = useMatchsQueries({
    ids,
  });
  const allMatchs = groupBy(
    orderBy(data, (d) => d.fixture.date),
    (d) => {
      const date = new Date(d.fixture.date);
      return new Intl.DateTimeFormat("fr-Fr", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      }).format(date);
      //return `${day}/${month}/${year}`
    }
  );
  console.log("viewer", ids);
  return (
    <Box>
      {Object.keys(allMatchs).map((date) => (
        <Box sx={{ paddingY: 2 }}>
          <MatchList subtitle={date} matchs={allMatchs[date]} />
        </Box>
      ))}
    </Box>
  );
};
