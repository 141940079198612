import React from "react";
import Drawer from "@mui/material/Drawer";
import { makeStyles, createStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useAuth, useRoutes } from "../../hooks";
import { useDarkMode } from "../../components";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppContext } from "../../context/app/AppContext";

const drawerWidth = 240;

type SidebarProps = {
  open: boolean;
  onClose: () => void;
};

export const Navigation: React.FC<SidebarProps> = ({
  open,
  onClose,
}: SidebarProps) => {
  const container = null; //window.document.body;
  const { navigate, paths } = useRoutes();
  const { darkMode, toggle } = useDarkMode();
  const { isLoggedIn } = useAuth();
  const { competitions } = useAppContext();

  const handleOnClick = ({ slug }: { slug: string }) => {
    onClose();
    navigate(paths.competitions.path.replace(":competition", slug))();
  };

  const handleOnNavigate = (path) => {
    onClose();
    navigate(path)();
  };

  const toggleDarkMode = () => {
    toggle();
    onClose();
  };

  return (
    <Drawer
      container={container}
      variant="temporary"
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: drawerWidth } }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={
              isLoggedIn
                ? () => handleOnNavigate(paths.profil.path)
                : () => handleOnNavigate(paths.login.path)
            }
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Compte" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleOnNavigate(paths.home.path)}>
          <ListItemText primary="Accueil" />
        </ListItemButton>
        <ListItemButton onClick={() => handleOnNavigate(paths.myList.path)}>
          <ListItemText primary="Ma liste" />
        </ListItemButton>
      </List>
      <Divider />
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Compétitions
          </ListSubheader>
        }
      >
        {competitions.map((competition) => (
          <ListItemButton onClick={() => handleOnClick(competition)}>
            <ListItemText primary={competition.title} />
          </ListItemButton>
        ))}
      </List>
      <Divider />

      <List
        sx={{
          width: "100%",
          height: 500,
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={toggleDarkMode}>
          <ListItemText primary={darkMode ? "Mode Clair" : "Mode Sombre"} />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Navigation;
