import { Box, Skeleton } from "@mui/material";
import React from "react";

type ArticleSkeletonProps = {
  display?: "column" | "row";
};

export const ArticleSkeleton: React.FC<ArticleSkeletonProps> = ({
  display="row",
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: display, gap: 2, paddingY: 3 }}>
      <Skeleton variant="rectangular" width={display === "row" ? 200: "100%"} height={display === "row" ? 120: 320} />
      <Box sx={{ flex:1}}>
        <Skeleton
          variant="rectangular"
          width={100}
          height={20}
          
        />
        <Skeleton variant="text" sx={{ marginTop: 1, fontSize: "2rem" }} />
      </Box>
    </Box>
  );
};
