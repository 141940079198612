import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { DynamicForm, AuthContainer, Layout } from "../../components";
import { useAuthApi, useRoutes } from "../../hooks";

export const ForgetPassword: React.FC = () => {
  const { paths, navigate } = useRoutes();
  const navigateToRegister = navigate(paths.login.path);

  const { forgetPassword } = useAuthApi();
  const [error, setError] = useState("");
  const [errorType, setErrorType] = useState("error");

  const handleOnSubmit = useCallback(
    async ({ email }) => {
      try {
        await forgetPassword(email);
        setErrorType("info");
        setError("auth/reset-password-success");
      } catch (error) {
        setErrorType("error");
        setError("auth/wrong-credentials");
      }
    },
    [setError, forgetPassword]
  );

  return (
    <Layout>
      <AuthContainer title="Mot de passe oublié">
        <DynamicForm
          fields={{
            email: {
              label: "Email",
              type: "text",
            },
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
          })}
          error={error}
          errorType={errorType as any}
          cancelTitle="Se connecter"
          submitTitle="Valider"
          onSubmit={handleOnSubmit}
          onCancel={navigateToRegister}
        />
      </AuthContainer>
    </Layout>
  );
};

export default ForgetPassword;
