import { useState } from "react";

type DataDialog<T> = {
  open: boolean,
  data?: T,
}
export const useDialog = <T>(): [
  DataDialog<T>,
  (data?: T) => void,
  () => void,
] => {
  const [value, setValue] = useState<DataDialog<T>>({
    open: false,
    data: undefined,
  });


  const openDialog = (data?: T) => {
    setValue({ data, open: true });
  };

  const closeDialog = () => {
    setValue({data: undefined, open: false});
  };

  return [value, openDialog, closeDialog];
};
