import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Standing as StandingType } from "../../types";
import { TeamDisplay } from "../teams";
import { Typography } from "@mui/material";

function Row(props: { row: StandingType; isCup?: boolean }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Box
            sx={{
              display: "flex",
              flex: 1,
              height: 50,
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>

            <Typography
              align="right"
              sx={{ width: 15, paddingLeft: 1, paddingRight: 2 }}
            >
              {row.rank}
            </Typography>

            <TeamDisplay
              id={row.team.id}
              logo={row.team.logo}
              name={row.team.name}
              horizontalRatio={props?.isCup}
            />
          </Box>
        </TableCell>
        <TableCell align="right">{row.all.played}</TableCell>
        <TableCell align="right">{row.goalsDiff}</TableCell>
        <TableCell align="right" sx={{ fontWeight: "bold" }}>
          {row.points}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>MJ</TableCell>
                    <TableCell>V</TableCell>
                    <TableCell align="right">N</TableCell>
                    <TableCell align="right">D</TableCell>
                    <TableCell align="right">BM</TableCell>
                    <TableCell align="right">BC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ borderBottom: "none" }}>
                  <TableRow sx={{ borderBottom: "none" }}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ borderBottom: "none" }}
                    >
                      {row.all.played}
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {row.all.win}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: "none" }}>
                      {row.all.draw}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: "none" }}>
                      {row.all.lose}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: "none" }}>
                      {row.all.goals.for}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: "none" }}>
                      {row.all.goals.against}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

type StandingProps = {
  standings: StandingType[];
  isCup?: boolean;
};

export const Standing: React.FC<StandingProps> = ({ standings, isCup }) => {
  return (
    <TableContainer component={"div"}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Equipes</TableCell>
            <TableCell align="right">MJ</TableCell>
            <TableCell align="right">+/-</TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Pts
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {standings.map((standing) => (
            <Row key={standing.team.name} row={standing} isCup={isCup} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
