import React from "react";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Route } from "../../routes";
import { useRoutes } from "../../hooks";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

type TabbarProps = {
  tabs: Route[];
};

export const Tabbar: React.FC<TabbarProps> = ({ tabs = [] }) => {
  const { navigate, currentPath } = useRoutes();
  const [value, setValue] = React.useState(currentPath?.path);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();

  const handleChange = (_event: React.SyntheticEvent, path: string) => {
    navigate(path)();
  };

  React.useEffect(() => {
    const path = tabs?.filter((tab) => location.pathname.includes(tab.path));
    setValue(path[path.length - 1]?.path);
  }, [location.pathname]);

  return (
    <Container maxWidth="md" sx={{}}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant={matches ? "fullWidth" : "scrollable"}
      >
        {tabs.map(({ label, path }) => (
          <Tab key={path} label={label} value={path} />
        ))}
      </Tabs>
    </Container>
  );
};
