import Axios from "axios";
import {
  mapCoach,
  mapMatchs,
  mapPlayers,
  mapStandings,
  mapTeams,
  mapScorers,
} from "./api.mapper";
import { CompetitionParams, MatchParams, RoundParams } from "./api.typing";

const request = Axios.create({
  baseURL: "https://api-football-v1.p.rapidapi.com/v3",
  timeout: 1000,
  headers: {
    "x-rapidapi-host": "api-football-v1.p.rapidapi.com",
    "x-rapidapi-key": "42ad7aeca7mshd0c54adb61f01e4p153b6bjsn4da3c6de775f",
  },
});

export const getTeams = (params: CompetitionParams) => {
  return request
    .get("/teams", { params })
    .then((request) => request.data.response)
    .then(mapTeams);
};

export const getPlayers = (team) => {
  return request
    .get("/players/squads", { params: { team } })
    .then((request) => request.data.response)
    .then(mapPlayers);
};

export const getCoach = (team) => {
  return request
    .get("/coachs", { params: { team } })
    .then((request) => request.data.response)
    .then(mapCoach);
};

export const getRounds = (params: RoundParams) => {
  return request
    .get("/fixtures/rounds", { params })
    .then((request) => request.data.response)
    .then((response) => (response as string[]) || []);
};


export const getMatchs = (params: MatchParams) => {
  return request
    .get("/fixtures", { params })
    .then((request) => request.data.response)
    .then(mapMatchs);
};


export const getRankings = (params: CompetitionParams) => {
  return request
    .get("/standings", { params })
    .then((request) => request.data.response)
    .then(mapStandings);
};

export const getScorers = (params: CompetitionParams) => {
  return request
    .get("/players/topscorers", { params })
    .then((request) => request.data.response)
    .then(mapScorers);
};
