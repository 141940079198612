import { useQuery, QueryOptions } from "react-query";
import {
  getCoach,
  getPlayers,
  getTeams,
  getRounds,
  getMatchs,
  getRankings,
  getScorers,
} from "../api/api";

import {
  collection,
  getFirestore,
  Query,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import { Article, Team } from "../types";
import { CompetitionParams, MatchParams, RoundParams } from "../api/api.typing";

const getLocalTeams = async () => {
  let dataValues:Record<string, Team> = {};
  const query = collection(getFirestore(), "teams") as Query<Team>;
  const docs = await getDocs<Team>(query);
  docs.forEach((doc) => {
    dataValues[doc.id] = doc.data(); 
  });
  return dataValues;
};

export const getArticles = async () => {
  const dataValues = new Array<Article>();
  const db = getFirestore();
  //connectFirestoreEmulator(db, 'localhost', 8080);

  const q = query(
    collection(db, "articles"),
    orderBy("created", "desc")
  ) as Query<Article>;
  const docs = await getDocs<Article>(q);
  docs.forEach((doc) => {
    dataValues.push({ id: doc.id, ...doc.data() });
  });
  return dataValues;
};

export const getArticleById = async (articleId) => {
  const db = getFirestore();
  const docRef = doc(db, "articles", articleId);
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};
export const useLocalTeamsQueries = () => {
  return useQuery("localTeams", getLocalTeams);
};

export const useTeamsQueries = (params: CompetitionParams, enabled = false) => {
  return useQuery(["teams", params.league], () => getTeams(params), {
    cacheTime: 24 * 60 * 60,
    enabled,
  });
};

export const usePlayersQueries = (team) => {
  return useQuery("players/" + team, () => getPlayers(team), {
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: !!team,
  });
};

export const useCoachQueries = (team) => {
  return useQuery("coach/" + team, () => getCoach(team), {
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: !!team,
    // staleTime:
  });
};

export const useRoundQueries = (params: RoundParams, enabled) => {
  return useQuery(
    ["rounds", params.league, params.season, params.current],
    () => getRounds(params),
    {
      cacheTime: 24 * 60 * 60,
      enabled,
    }
  );
};

export const useMatchsQueries = (
  params: MatchParams,
  options?: QueryOptions
) => {
  return useQuery(["matchs", JSON.stringify(params)], () => getMatchs(params), {
    cacheTime: options?.cacheTime ?? 24 * 60 * 60,
    enabled: !!params,
  });
};

export const useStandingsQueries = (params: CompetitionParams, enabled) => {
  return useQuery(["standings", params.league], () => getRankings(params), {
    cacheTime: 24 * 60 * 60,
    enabled,
  });
};

export const useScorersQueries = (params: CompetitionParams, enabled) => {
  return useQuery(["scorers", params.league], () => getScorers(params), {
    cacheTime: 24 * 60 * 60,
    enabled,
  });
};


