import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getRoundInFrench } from "../../routes";
import { useRoundQueries } from "../../hooks";

type MatchsRoundProps = {
  competition: { competitionId: number; season: number };
  selectedRound: string;
  onChange: (round: string) => void;
};

export const MatchsRound: React.FC<MatchsRoundProps> = ({
  competition,
  selectedRound,
  onChange,
}) => {
  const { data: rounds = [] } = useRoundQueries(
    { league: competition?.competitionId!, season: competition?.season! },
    !!competition
  );

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <Select
          value={selectedRound}
          onChange={handleChange}
          autoWidth
        >
          {rounds.map((round) => (
            <MenuItem key={round} value={round}>
              {getRoundInFrench(round)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
