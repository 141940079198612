import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Team as TeamType } from "types";
import { Team } from "./team";

type TeamListProps = {
  teams: TeamType[];
  onClick?: (id?: string) => void;
};

export const TeamList: React.FC<TeamListProps> = ({ teams, onClick }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {teams
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((team) => (
            <Grid key={team.id} item xs={12} sm={6} md={6}>
              <Team {...team} onClick={onClick} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
