import React from "react";
import { Box, Typography } from "@mui/material";
import { MatchEvent } from "types";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

type MatchPenaltiesProps = {
  events: MatchEvent[];
  teamName: string;
  alignement: "start" | "end";
};

export const MatchPenalties: React.FC<MatchPenaltiesProps> = ({
  events,
  alignement,
  teamName,
}) => {
  const scorers = events?.filter(
    (event) =>
      event.team.name === teamName && event.comments === "Penalty Shootout"
  );
  return (
    <Box>
      {scorers.map((scorer, index) => (
        <Typography
          key={`${scorer.player.id} - ${scorer.time.elapsed}`}
          variant="body2"
          color="text.secondary"
          textAlign="center"
          sx={{
            width: "auto",
            marginTop: index === 0 ? 2 : 0,
            display: "flex",
            flexDirection: alignement === "end" ? "row" : "row-reverse",
            gap: 1,
            alignSelf: `flex-${alignement}`,
          }}
          component="div"
          gutterBottom
        >
          <FiberManualRecordIcon
            fontSize="small"
            sx={{
              color: scorer.detail.includes("Missed Penalty") ? "red" : "green",
            }}
          />
          {scorer.player.name}
        </Typography>
      ))}
    </Box>
  );
};
