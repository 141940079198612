import React from "react";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import { makeStyles, createStyles } from "@mui/styles";
import SidebarMenu from "./admin-sidebar-menu";

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

type SidebarProps = {
  mobileOpened?: boolean;
  onClose?: () => void;
};

export const AdminSidebar: React.FC<SidebarProps> = ({
  mobileOpened,
  onClose,
}: SidebarProps) => {
  const classes = useStyles();
  const container = null //window.document.body;

  return (
    <nav className={classes.drawer}>
      <Drawer
        sx={{ display: { md: "none", sm: "block" } }}
        container={container}
        variant="temporary"
        anchor="left"
        open={mobileOpened}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SidebarMenu onChange={onClose as any} />
      </Drawer>
      <Drawer
        sx={{ display: {  xs: "none", md: "block" } }}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
      >
        <SidebarMenu onChange={() => {}} />
      </Drawer>
    </nav>
  );
};

export default AdminSidebar;
