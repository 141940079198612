import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useRoutes } from "../../hooks";
import { Box, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SportsSoccer from "@mui/icons-material/SportsSoccer";
import { Navigation } from "../Navigation";
import { useSelectedCompetition } from "../../hooks";

export const Header: React.FC = ({ children }) => {
  const { navigate, paths } = useRoutes();
  const [open, setOpen] = useState(false);

  const navigateToHome = navigate(paths.home.path);
  const navigateToLive = navigate(paths.live.path);

  const competition = ''
  return (
    <>
      <AppBar position="fixed">
        <Toolbar sx={{ display: "flex", paddingLeft: 2, paddingRight: 2 }}>
          <Box>
            <IconButton sx={{ color: "white" }} onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {competition ? (
              <Typography variant="h4">{competition}</Typography>
            ) : (
              <img
                style={{ cursor: "pointer" }}
                src={`/logo.png`}
                alt="logo"
                onClick={navigateToHome}
              />
            )}
          </Box>
          <IconButton sx={{ color: "white" }} onClick={navigateToLive}>
            <SportsSoccer />
          </IconButton>
        </Toolbar>
        {children}
      </AppBar>
      <Navigation open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Header;
