import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { SubmitButton } from "../submit-button";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  gap: `${theme.spacing(2)}`,
}));

export type FormActionsProps = {
  submitTitle: string;
  cancelTitle: string;
  onCancel: () => void;
};

export const FormActions: React.FC<FormActionsProps> = ({
  submitTitle,
  cancelTitle,
  onCancel,
}) => {
  const { isSubmitting, isValid } = useFormikContext();

  return (
    <Container>
      <SubmitButton
        title={submitTitle}
        variant="contained"
        color="primary"
        disabled={!isValid || isSubmitting}
        isSubmitting={isSubmitting}
      />
      <Button
        color="primary"
        onClick={onCancel}
      >
        {cancelTitle}
      </Button>
    </Container>
  );
};

export default FormActions;
