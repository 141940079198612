import { ArticleItem } from "./article-item";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { Article } from "types";
import { ArticleSkeleton } from "./article-skeleton";
import { Typography } from "@mui/material";

type ArticlesProps = {
  articles: Article[];
  title?: string;
  firstDivider?: boolean;
  loading?: boolean;
  onClick?: (articleId?: string) => void;
};

export const Articles: React.FC<ArticlesProps> = ({
  articles,
  title,
  firstDivider,
  loading,
  onClick
}) => {

  if (loading) {
    return (
      <Box>
        {firstDivider && <Divider />}
        <ArticleSkeleton />
        <Divider />
        <ArticleSkeleton />
        <Divider />
        <ArticleSkeleton />
        <Divider />
        <ArticleSkeleton />
      </Box>
    );
  }

  if (!articles.length) {
    return <Typography>Pas d'articles</Typography>;
  }

  return (
    <Box>
      {title && <Typography variant="h6">{title}</Typography>}
      {articles.map((article, index) => (
        <Box key={article.id}>
          {(firstDivider || !!index) && <Divider />}
          <ArticleItem {...article} onClick={onClick} />
        </Box>
      ))}
      <Divider />
    </Box>
  );
};
