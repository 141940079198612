import React from "react";
import { Navigate, useLocation } from "react-router-dom";

type PrivateRouteProps = {
  required: boolean;
  navigateTo: string;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  required,
  navigateTo,
}) => {
  const location = useLocation();

  if (required) {
    return <Navigate to={navigateTo} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
