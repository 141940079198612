import React from "react";
import { makeStyles } from "@mui/styles";
import { Footer } from "../footer";

const useStyles = makeStyles(() => ({
  content: {
    width: "100vw",
    minHeight: "100vh",
  },
}));

export const Layout: React.FC = ({ children }) => {
  const classNames = useStyles();
  return (
    <div>
      <div className={classNames.content}>{children}</div>
    </div>
  );
};

export default Layout;
