import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  limit,
  query,
  Query,
  orderBy,
  QueryDocumentSnapshot,
  SnapshotOptions,
  where,
} from "firebase/firestore";
import {
  useFirestoreQueryData,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";
import { CollectionParams, QueryParams } from "api/api.typing";
import { useMemo } from "react";

const getListConverter = <T>() => ({
  toFirestore(data): DocumentData {
    console.log("to firestore");
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
    const id = snapshot.id;
    const data = snapshot.data(options)!;
    return ({ id, ...data } as unknown) as T;
  },
});

const getList = <T>(key: string, params?: CollectionParams) => {
  let myCollection = collection(getFirestore(), key) as Query<T>;
  if (params?.where?.length) {
    params.where.forEach(([field, condition, value]) => {
      console.log("where", condition, value)
      myCollection = query(myCollection, where(field, condition, value));
    });
  }
  if (params?.max) {
    myCollection = query(myCollection, limit(params.max));
  }
  if (params?.orderBy) {
    myCollection = query(
      myCollection,
      orderBy(params.orderBy?.key, params.orderBy?.order)
    );
  }

  myCollection = myCollection.withConverter(getListConverter<T>());
  return myCollection;
};

export const useCollectionQuery = <T>(
  key: string | string,
  params?: CollectionParams,
  queryParams?: QueryParams
) => {
  const query =  useMemo(() => getList<T>(key, params), [])
  return useFirestoreQueryData(
    queryParams?.key || [key],
    query,
    { subscribe: false },
    {
      enabled: queryParams?.enabled,
     

    }
  );
};

export const useDocQuery = <T>(
  key: string,
  subkey: string,
  params?: QueryParams
) => {
  return useFirestoreDocumentData<T>(
    [key, subkey],
    doc(getFirestore(), key, subkey) as DocumentReference<T>,
    {
      subscribe: false,
    },
    {
      enabled: params?.enabled,
    }
  );
};
