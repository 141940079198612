  import { Box, IconButton } from "@mui/material";
import LikeOutlined from "@mui/icons-material/ThumbUpAltOutlined";
import Like from "@mui/icons-material/ThumbUpAlt";
import Comment from "@mui/icons-material/Comment";
import Share from "@mui/icons-material/Share";
import Bookmark from "@mui/icons-material/Bookmark";
import BookmarkOutlined from "@mui/icons-material/BookmarkBorder";
import { RWebShare } from "react-web-share";

type ArticlesocialProps = {
  liked?: boolean;
  isFavourite?: boolean;
  title: string;
  onLike: () => void;
  onComment: () => void;
  onFavourite: () => void;
};

export const Articlesocial: React.FC<ArticlesocialProps> = ({
  isFavourite,
  liked,
  title,
  onLike,
  onComment,
  onFavourite,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <IconButton onClick={onLike}>
          {liked ? <Like /> : <LikeOutlined />}
        </IconButton>
        <IconButton onClick={onComment}>
          <Comment />
        </IconButton>
        <RWebShare
          data={{
            text: title,
            url: typeof window !== "undefined"  ? window.location.href : "",
            title: "Partager",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <IconButton aria-label="fingerprint">
            <Share />
          </IconButton>
        </RWebShare>
      </Box>
      <IconButton onClick={onFavourite}>
        {isFavourite ? <Bookmark /> : <BookmarkOutlined />}
      </IconButton>
    </Box>
  );
};
