import { ArticleItem, Articles } from "../../components";
import { useArticlesQueries } from "../../hooks";
import { Box } from "@mui/material";
import { ArticleSkeleton } from "../../components/articles/article-skeleton";
import MetaTags from "react-meta-tags";
import { Article } from "types";

type HomeProps = {
  articles: Article[];
  isSuccess: boolean;
  onClick: (articleId: any) => void
}

export const Home: React.FC<HomeProps> = ({ articles, isSuccess, onClick }) => {
  return (
    <Box>
      {!isSuccess &&
        <ArticleSkeleton display="column" />
      }
      {isSuccess && !!articles.length &&
        <ArticleItem
          {...articles[0]}
          display="column"
          sx={{ paddingTop: 0 }}
          onClick={onClick}
        />
      }
      <Articles
        articles={articles.slice(1)}
        loading={!isSuccess}
        firstDivider={true}
        onClick={onClick}
      />
    </Box>
  );
};

export default () => {
  const {
    data = [],
    isSuccess,
    handleOnClick,
  } = useArticlesQueries({ orderBy: { key: "created", order: "desc" } });

  return (
    <>
     <MetaTags>
        <title>Supfoot</title>
        <meta name="description" content="Le football africain dans une dimension supérieure" />
        <meta property="og:description" content="Le football africain dans une dimension supérieure" />
        <meta property="og:title" content='Supfoot' />
        <meta property="og:image" content='https://supfoot.com/logo192.png' />
      </MetaTags>
      <Home articles={data} isSuccess={isSuccess} onClick={handleOnClick} />
    </>
  )

}
