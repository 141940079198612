import React from "react";
import Fab, { FabProps } from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const PositionningFab = styled(Fab)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

export const AddFab: React.FC<FabProps> = (props) => (
  <PositionningFab {...props}>
    <AddIcon />
  </PositionningFab>
);

export default AddFab;
