import { Box, Container, Divider, Toolbar, Typography } from "@mui/material";
import { MatchList, MatchFilter, DateFilter, Header } from "../../components";
import { useCompetitions, useMatchsQueries } from "../../hooks";
import React, { useEffect, useState } from "react";

let timerId;

export const Live = () => {
  const [params, setParams] = useState<DateFilter>();
  const { data: competitions = [] } = useCompetitions();

  const matchParams = { date: params?.date };

  const { data = [], refetch } = useMatchsQueries(matchParams, {
    cacheTime: 1000 * 60,
  });

  const handleOnChange = (filter: DateFilter) => {
    setParams({ ...params, ...filter });
  };

  console.log('competitions', competitions)

  const matchByCompetitions = competitions
    .map((competition) => ({
      ...competition,
      matchs: data.filter((match) => match.league.id === +competition.competitionId),
    }))
    .filter((c) => !!c.matchs.length);

  const hasLive = !!matchByCompetitions.find((comp) =>
    comp.matchs.find(
      (m) => !["Match Finished", "Not Started"].includes(m.fixture.status.long)
    )
  );

  useEffect(() => {
    timerId = setInterval(() => {
      console.log("params.today", params);
      if (params?.today && hasLive) {
        console.log("ouloulou");
        refetch();
      }
    }, 1000 * 60);

    return () => {
      clearInterval(timerId);
    };
  }, [params?.today]);

  return (
    <Box>
      <Header>
        <MatchFilter onChange={handleOnChange} />
      </Header>
      <Container maxWidth="md" sx={{ minHeight: "100Vh", padding: 2 }}>
        <Toolbar />
        <Toolbar />
        {!matchByCompetitions.length && <Typography>Pas de Matchs</Typography>}
        {matchByCompetitions.map((competition, index) => (
          <>
            {index > 0 && (
              <Box sx={{ paddingY: 1 }}>
                <Divider />
              </Box>
            )}
            <MatchList
              key={competition.id}
              subtitle={competition.title}
              matchs={competition.matchs}
              isCup={competition.isCup}
            />
          </>
        ))}
      </Container>
    </Box>
  );
};

export default Live;
