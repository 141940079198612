import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import {
  DynamicForm,
  Social,
  AuthContainer,
  Layout,
} from "../../components";
import { useAuthApi, useRoutes } from "../../hooks";

export const Register: React.FC = () => {
  const { paths, navigate } = useRoutes();
  const { register, updateProfil } = useAuthApi();
  const [error, setError] = useState("");

  const navigateToLogin = navigate(paths.login.path);
  const navigateToHome = navigate(paths.home.path);

  const handleOnSubmit = useCallback(
    async ({ email, password, firstName, lastName }) => {
      try {
        const response = await register(email, password);
        const id = response?.user?.uid;
        if (id) {
          await updateProfil({ firstName, lastName });
        }
        navigateToHome();
      } catch (error) {
        setError("error.code");
      }
    },
    [setError, register, updateProfil, navigateToHome]
  );

  return (
    <Layout>
      <AuthContainer title="Inscription">
        <DynamicForm
          header={<Social onSuccess={navigateToHome} onError={setError} />}
          fields={{
            firstName: {
              label: "Prénom",
              type: "text",
            },
            lastName: {
              label: "Nom",
              type: "text",
            },
            email: {
              label: "Email",
              type: "text",
            },
            password: {
              label: "Mot de passe",
              type: "password",
            },
            confirmPassword: {
              label: "Confirmation du mot de passe",
              type: "password",
            },
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("ce champ est requis"),
            lastName: Yup.string().required("Ce champ est requis"),
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
            password: Yup.string()
              .min(6, "6 charactères minimum")
              .required("Ce champ est requis"),
            confirmPassword: Yup.string()
              .oneOf([
                Yup.ref("password"),
                "Les mots de pass ne se correspondent pas",
              ])
              .required("Ce champ est required"),
          })}
          error={error}
          errorType="error"
          cancelTitle="Se connecter"
          submitTitle="S'inscrire"
          onSubmit={handleOnSubmit}
          onCancel={navigateToLogin}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Register;
