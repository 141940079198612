import React from "react";
import { Crud } from "../../components";
import slugify from "slugify";
import * as Yup from "yup";

const Competitions = () => {
  const handleTransform = (values) => {
    console.log("transform");
    const slug = slugify(values.title, "_");
    return { ...values, slug };
  };
  return (
    <Crud
      name="competitions"
      initialValues={{
        competitionId: "",
        title: "",
        season: "",
        isCup: false,
        order: 1,
      }}
      fields={{
        competitionId: {
          label: "Id",
        },
        title: {
          label: "Title",
        },
        name: {
          label: "Title complet",
        },
        season: {
          label: "Année",
          type: "number"
        },
        order: {
          label: "Ordre",
          type: "number",
        },
        isCup: {
          label: "Coupe",
          type: "checkbox",
        },
      }}
      validationSchema={Yup.object().shape({
        competitionId: Yup.number().required("Ce champ est requis"),
        title: Yup.string().required("Ce champ est requis"),
        season: Yup.number().required("Ce champ est requis"),
      })}
      columns={[
        {
          field: "competitionId",
          headerName: "Id",
          width: 100,
        },
        {
          field: "title",
          headerName: "Titre",
          width: 250,
        },
        {
          field: "season",
          headerName: "Saison",
          width: 100,
        },
        {
          field: "order",
          headerName: "Ordre",
          width: 100,
        }
      ]}
      transform={handleTransform}
    />
  );
};

export default Competitions;
