import { useCallback, useLayoutEffect, useMemo } from "react";
import { useNavigate, useLocation, NavigateOptions } from "react-router-dom";
import { paths } from "../routes";

type QueryString = {
  [key: string]: any;
};

export const useRoutes = () => {
  const go = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(() => {
    let params: { [key: string]: any } = {};
    const searchParams = new URLSearchParams(location.search);

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }, [location]);

  const navigate = useCallback(
    (path: string, options?: NavigateOptions) => () => {
      go(path, options);
    },
    [go]
  );

  const goBack = useCallback(() => {
    go(-1);
  }, [go]);

  const setQueryParams = useCallback(
    (queries: QueryString) => {
      go({ search: `?${new URLSearchParams(queries).toString()}` });
    },
    [go]
  );
  
  const currentPath = useMemo(
    () => Object.values(paths).find(({ path }) => path === location.pathname),
    [location]
  );
 
  return {
    navigate,
    paths,
    goBack,
    setQueryParams,
    queryParams,
    currentPath,
  };
};

type useBlockNavigationType = {
  isLoggedIn: boolean;
  navigateTo: string;
};
export const useBlockNavigation = ({
  isLoggedIn,
  navigateTo,
}: useBlockNavigationType) => {
  const { navigate } = useRoutes();
  // const { isLoggedIn: userLoggedIn } = useAuth();
  const userLoggedIn = false;
  useLayoutEffect(() => {
    if (isLoggedIn === userLoggedIn) {
      navigate(navigateTo)();
    }
  }, [navigate, navigateTo, userLoggedIn, isLoggedIn]);
};
