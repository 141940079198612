import { Article } from "../types";
import { CollectionParams, QueryParams } from "../api/api.typing";
import {  useCallback } from "react";
import { convertFromRaw } from "draft-js";
import { useCollectionQuery } from "./queries";
import { useRoutes } from "./routes";
import { useQueryClient } from "react-query";
import slugify from "slugify";

export const useArticlesQueries = (
  params?: CollectionParams,
  queryParams?: QueryParams
) => {
  const query = useCollectionQuery<Article>(
    "articles",
    params,
    queryParams
  );
  const { paths, navigate } = useRoutes();
  const queryClient = useQueryClient();

  const handleOnClick = useCallback(
    (articleId) => {
      const article = query.data?.find((art) => art.id === articleId);
      queryClient.setQueryData(["articles", articleId], article);
      const slug = article?.title ? slugify(article?.title) : '';
      navigate(paths.viewArticle.path
        .replace(":articleId", articleId)
        .replace(":slug", slug)
        )();
    },
    [queryClient, query.data]
  );

  return {
    ...query,
    handleOnClick,
  };
};

export const getArticleDescription = (content: string, length = 255) => {
  try {
    const state = convertFromRaw(JSON.parse(content));
    return state.getPlainText().slice(0, length);
  } catch (error) {
    return "";
  }
};
