import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { GROUPS } from "../../constants";

type StandingSelectProps = {
  value: string;
  length: number;
  onChange: (round: string) => void;
};

export const StandingSelect: React.FC<StandingSelectProps> = ({
  onChange,
  value,
  length,
}) => {

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 80 }}>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={handleChange}
          autoWidth
        >
          {new Array(length).fill(1).map((round, index) => (
            <MenuItem key={index} value={index + ""}>
              {GROUPS[index]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
