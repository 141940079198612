import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import SoccerLineUp from "react-soccer-lineup";
import { LineUp, Player, Team } from "types";

const getPlayers = (players: { player: Player }[], position: string) => {
  return players
    .filter(({ player }) => player.pos === position)
    .map(({ player }) => ({ name: player.name, number: player.number }));
};

const getSquad = (lineUp: LineUp) => {
  const gk = getPlayers(lineUp.startXI, "G")[0];
  const df = getPlayers(lineUp.startXI, "D");

  const cm = getPlayers(lineUp.startXI, "M");
  const fw = getPlayers(lineUp.startXI, "F");

  return { gk, df, cm, fw };
};

const TeamFormation: React.FC<{ team: Team; formation: string }> = ({
  team,
  formation,
}) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <img width="45" height="45" src={team?.logo} />
    <Box sx={{ marginLeft: 1 }}>
      <Typography variant="subtitle1">{team.name}</Typography>
      <Typography variant="h6">{formation}</Typography>
    </Box>
  </Box>
);

export const MatchLineUps: React.FC<{ lineups: LineUp[] }> = ({ lineups }) => {
  if (!lineups.length) {
    return <Typography>Pas disponible</Typography>
  }
  const homeSquad = getSquad(lineups[0]);
  const awaySquad = getSquad(lineups[1]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        {lineups.map((lineup) => (
          <TeamFormation team={lineup.team} formation={lineup.formation} />
        ))}
      </Box>
      <SoccerLineUp
        size={"responsive"}
        color={"green"}
        pattern={"lines"}
        homeTeam={{ squad: homeSquad }}
        awayTeam={{ squad: awaySquad }}
      />
      <Typography textAlign="center" sx={{ marginY: 2 }} variant="h6">
        Remplaçants
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {lineups.map((lineUp) => (
          <List>
            {lineUp.substitutes.map(({ player }) => (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <>
                      <strong>{player.number}</strong> - {player.name}
                    </>
                  }
                ></ListItemText>
              </ListItem>
            ))}
          </List>
        ))}
      </Box>
      <Divider />
      <Typography textAlign="center" sx={{ marginY: 2 }} variant="h6">
        Entraineurs
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText primary={lineups[0].coach.name}></ListItemText>
          </ListItem>
        </List>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText primary={lineups[1].coach.name}></ListItemText>
          </ListItem>
        </List>
      </Box>
    </div>
  );
};
