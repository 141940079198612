import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAJ5x1nzwKHWWHdc-aN3RPNzH1dxS9nRgM",
  authDomain: "supfoot-2e0c1.firebaseapp.com",
  databaseURL: "https://supfoot-2e0c1.firebaseio.com",
  projectId: "supfoot-2e0c1",
  storageBucket: "supfoot-2e0c1.appspot.com",
  messagingSenderId: "224373861668",
  appId: "1:224373861668:web:5d3d63e3b3c18502d457b1",
};

initializeApp(firebaseConfig);
