import React, { useCallback, useState } from "react";
import { DynamicForm, SubmitButton } from "../../components";
import { useAuth, useAuthApi } from "../../hooks/auth";
import * as Yup from "yup";

const ChangePassword = () => {
  const [error, setError] = useState("");
  const { user } = useAuth();
  const { updatePassword } = useAuthApi();

  const handleOnSubmit = useCallback(
    async ({ password, newPassword }) => {
      try {
        await updatePassword(user?.email +"", password, newPassword);
        console.log("response", "ouloulou");
      } catch (error) {
        setError("auth/change-profil");
      }
    },
    [updatePassword, user]
  );

  return (
    <DynamicForm
      initialValue={user}
      fields={{
        password: {
          label: "Mot de passe",
          type: "password",
        },
        newPassword: {
          label: "Nouveau mot de passe",
          type: "password",
        },
        confirmPassword: {
          label: "Confirmation du mot de passe",
          type: "password",
        },
      }}
      onSubmit={handleOnSubmit}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, "6 charactères minimum")
          .required("Ce champ est requis"),
        newPassword: Yup.string()
          .min(6, "6 charactères minimum")
          .required("Ce champ est requis"),
        confirmPassword: Yup.string()
          .oneOf([
            Yup.ref("newPassword"),
            "Les mots de pass ne se correspondent pas",
          ])
          .required("Ce champ est required"),
      })}
      error={error}
    >
      {(form: any) => (
        <SubmitButton
          title="Valider"
          color="primary"
          variant="contained"
          disabled={!form.isValid || form.isSubmitting}
        />
      )}
    </DynamicForm>
  );
};

export default ChangePassword;
