import React, { useCallback, useState, useLayoutEffect } from "react";
import * as Yup from "yup";
import {
  DynamicForm,
  AuthContainer,
  Layout,
} from "../../components";
import { useAuthApi, useRoutes } from "../../hooks";


export const Auth: React.FC = () => {
  const { paths, navigate, queryParams } = useRoutes();
  const { mode, oobCode } = queryParams;
  const { resetPassword } = useAuthApi();
  const [error, setError] = useState("");

  const navigateToLogin = navigate(paths.login.path);
  const navigateToHome = navigate(paths.home.path);

  const handleOnSubmit = useCallback(
    async ({ password }) => {
      try {
        await resetPassword(password, oobCode);
        navigateToLogin();
      } catch (error) {
        setError("error.code");
      }
    },
    [setError, oobCode, navigateToLogin, resetPassword]
  );

  useLayoutEffect(() => {
    if (mode !== "resetPassword" || !oobCode) {
      navigateToHome();
    }
  }, [mode, oobCode, navigateToHome]);

  return (
    <Layout>
      <AuthContainer title="Reinitialisation du mot de passe">
        <DynamicForm
          fields={{
            password: {
              label: "Nouveau mot de passe",
              type: "password",
            },
            confirmPassword: {
              label: "Confirmation du mot de passe",
              type: "password",
            },
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .min(6, "6 charactères minimum")
              .required("Ce champ est requis"),
            confirmPassword: Yup.string()
              .oneOf([
                Yup.ref("password"),
                "Les mots de pass ne se correspondent pas",
              ])
              .required("Ce champ est required"),
          })}
          error={error}
          errorType="error"
          cancelTitle="Se connecter"
          submitTitle="Valider"
          onSubmit={handleOnSubmit}
          onCancel={navigateToLogin}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Auth;
