import { documentId } from "firebase/firestore";
import { useArticlesQueries, useAuth, useRoutes } from "../../hooks";
import { Articles, Info } from "../../components";
import { useDocQuery } from "../../hooks/queries";
import BookMark from "@mui/icons-material/Bookmark";
import BookmarkOutlined from "@mui/icons-material/BookmarkBorder";
import { Article } from "types";

type MyListProps = {
  articles: Article[],
  isLoggedIn: boolean,
  isSuccess: boolean,
  onClick?: (articleId?: string) => void,
  onLogin?: () => void,
  onRegister?: () => void
}

export const MyList: React.FC<MyListProps> = ({ articles, isLoggedIn, isSuccess, onClick, onLogin, onRegister }) => {





  if (!isLoggedIn) {
    return (
      <Info
        title="Pour ajouter un contenu à votre liste, rejoignez notre communauté."
        icon={<BookMark sx={{ fontSize: "128px" }} />}
        primaryTitle="Créer un compte"
        secondaryTitle="Se connecter"
        onPrimaryClick={onLogin}
        onSecondaryClik={onRegister}
      />
    );
  }

  if (isSuccess && !articles.length) {
    return (
      <Info
        title="Pas d'articles. Pour ajouter un contenu à votre liste, cliquez sur l'icone."
        icon={<BookmarkOutlined sx={{ fontSize: "128px" }} />}
      />
    );
  }

  return (
    <Articles title="Ma liste" articles={articles} loading={!isSuccess} onClick={onClick} />
  );
};


export default () => {

  const { user, isLoggedIn } = useAuth();
  const { data } = useDocQuery<{ favourites: string[] }>(
    "users",
    user?.id ?? "rr",
    {
      enabled: !!user?.id,
    }
  );

  const { data: articles = [], isSuccess, handleOnClick } = useArticlesQueries(
    {
      where: [[documentId(), "in", data?.favourites.length ? data?.favourites : ["r"]]],
    },
    { key: ["myList", user?.id ?? ""], enabled: !!data?.favourites?.length }
  );

  const { paths, navigate } = useRoutes();
  const login = navigate(paths.login.path);
  const register = navigate(paths.register.path);

  return (
    <MyList
      articles={articles}
      isLoggedIn={isLoggedIn}
      isSuccess={isSuccess}
      onClick={handleOnClick}
      onLogin={login}
      onRegister={register}
    />)
}