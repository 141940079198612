import React from "react";
import classnames from "classnames";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";

const errorMessages: { [key: string]: string } = {
  "auth/wrong-credentials": "Email ou mot de pass incorrects",
  "auth/email-already-in-use": "L'adresse email existe déjà",
  "auth/user-not-found": "L'adresse email n'existe pas",
  "auth/invalid-action-code": "Le code a expiré ou incorrect",
  "auth/cannot-reset-password": "Réinitialisation du mot de pass impossible",
  "auth/invalid-username": "Nom d'utilisateur incorrect",
  "report/submit-error": "Création ou Enregistrement du rapport impossible",
  "emargement/submit-error": "Création ou Validation impossible",
  "auth/change-profil": "Modification impossible",
  "auth/reset-password-success": "Un mail vous a été envoyé pour vous permettre de reinitaliser votre mot de passe. Merci de vérifier votre boite de reception."
};

const useStyles = makeStyles((theme: any) => ({
  main: {
    color: "#fff",
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
}));

type ErrorProps = {
  className?: string;
  errorCode: string;
  type?: "info" | "error";
};

export const Error: React.FC<ErrorProps> = ({
  className,
  errorCode,
  type = "info",
}: ErrorProps) => {
  const classes = useStyles();
  return (
    <Paper
      className={classnames(classes.main, classes[type], className)}
      elevation={0}
    >
      {errorMessages[errorCode] || "Erreur inconnue"}
    </Paper>
  );
};

export default Error;
