import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme: any) => ({
  separator: {
    margin: theme.spacing(2),
  },
}));

export const Placeholder = () => {
  const classes = useStyles();
  const theme: any = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  let videoNumber = 6;
  if (xs) {
    videoNumber = 2;
  }
  if (md) {
    videoNumber = 4;
  }
  if (lg) {
    videoNumber = 6;
  }

  return (
    <div>
      <Skeleton variant="text" width={200} height={50} />
      <div className={classes.separator}></div>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {new Array(videoNumber).fill(1).map((_, index) => (
            <Grid key={index} item xs={6} md={3} lg={2}>
              <Skeleton
                variant="rectangular"
                key={index}
                width={"100%"}
                height={120}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};



export default Placeholder;
