import { useParams } from "react-router-dom";
import { Competition } from "types";

import { useCollectionQuery } from "./queries";

export const useCompetitions = () => {
  return useCollectionQuery<Competition>("competitions", {
    orderBy: { key: "order" },
  });
};

export const useCompetition = () => {
  const params = useParams<{ competition?: string }>();
  const { data: competitions = [] } = useCompetitions();
  return competitions.find((compet) => compet.slug === params?.competition);
};

export const useSelectedCompetition = () => {
  const params = useParams<{ competition: string }>();
  const { data: competitions = [] } = useCompetitions();
  return competitions.find((comp) => comp.slug === params?.competition);
};
