import React, { useMemo, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  createTheme as createMuiTheme,
  ThemeProvider,
} from "@mui/material/styles";
import loadable from "@loadable/component";
import { Main } from "./pages";

import { Footer, Loader, useDarkMode } from "./components";
import { paths } from "./routes";

import { useAuth } from "./hooks/auth";
import { Paper } from "@mui/material";
import { PrivateRoute } from "./components/private-route";

const Admin = loadable(() => import("./pages/admin/admin"));
const Competitions = loadable(() => import("./pages/competitions/index"));
const Login = loadable(() => import("./pages/login/login"));
const Register = loadable(() => import("./pages/register/register"));
const ForgetPassword = loadable(
  () => import("./pages/forget-password/forget-password")
);
const Auth = loadable(() => import("./pages/auth/index"));
const Contact = loadable(() => import("./pages/contact/contact"));
const Live = loadable(() => import("./pages/matchs/live"));
const LiveMatch = loadable(() => import("./pages/matchs/liveMatch"));

declare module "@mui/material/styles" {
  interface Theme {
    MUIRichTextEditor: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    MUIRichTextEditor?: {
      danger?: string;
    };
  }
}

const createTheme = (darkMode: boolean) =>
  createMuiTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#212121",
        //dark: "#FFF‡‡‡‡‡",
        // contrastText: "#fff",
      },
      secondary: {
        //light: '#0066ff',
        main: "#FAFAFA",
        // dark: will be calculated from palette.secondary.main,
        //contrastText: '#ffcc00',
      },
    },
  });

const Root: React.FC = () => {
  const { darkMode } = useDarkMode();

  const { isLoggedIn } = useAuth();

  const theme = useMemo(() => createTheme(darkMode), [darkMode]);

  useEffect(() => {
    Competitions.preload();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0}>
        <Loader loading={false}>
          <Routes>
            <Route path={paths.login.path} element={<Login />} />
            <Route path={paths.register.path} element={<Register />} />
            <Route
              path={paths.forgetPassword.path}
              element={<ForgetPassword />}
            />
            <Route path={paths.auth.path} element={<Auth />} />
            <Route
              path={paths.admin.path + "/*"}
              element={
                <PrivateRoute required={false} navigateTo={paths.login.path}>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route path={paths.contact.path} element={<Contact />} />
            <Route
              path={paths.competitions.path + "/*"}
              element={<Competitions />}
            />
            <Route path={paths.liveMatch.path + "/*"} element={<LiveMatch />} />
            <Route path={paths.live.path} element={<Live />} />
            <Route path="/*" element={<Main />} />
          </Routes>
          <Footer />
        </Loader>
      </Paper>
    </ThemeProvider>
  );
};

export default Root;
