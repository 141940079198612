import { Team, Player, Match, Standing, Statistic, PlayerScorer } from "../types";
type TeamDTo = { team: Team };
type PlayersDto = { team: Team; players: Player[] };
type CoachDto = { firstname: string; lastname: string };
type StandingDto = { league: { id: number; standings: Standing[][] } };
type ScorerDto = { player: Player; statistics: Statistic[] };

export const mapTeams = (teams: TeamDTo[]) => teams.map((dto) => dto.team);
export const mapPlayers = (data: PlayersDto[]) => data[0] || [];
export const mapCoach = (data: CoachDto[]) => data[0] || [];
export const mapMatchs = (data: Match[]) => data || [];
export const mapStandings = (data: StandingDto[]) =>
  data?.[0]?.league?.standings || [];

export const mapScorers = (data: ScorerDto[]) : PlayerScorer[] => {
  return data.map((d) => ({
    ...d,
    statistics: d.statistics[0],
  }));
};
