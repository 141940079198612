import { Box, TextField, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import CommentAvatar from "./comment-avatar";


import loadable from "@loadable/component";

//const EmojiSelector = loadable(() => import('../../components/emoji-selector'), { ssr: false })


type CommentInputProps = {
  author?: string;
  defaultValue?: string;
  focus?: boolean;
  disabled?: boolean;
  onSubmit: (text: string) => void;
  onCancel?: () => void;
  onFocus?: () => void;
};

const CommentInput: React.FC<CommentInputProps> = ({
  author,
  defaultValue,
  focus,
  disabled,
  onSubmit,
  onCancel,
  onFocus,
}) => {
  const [value, setValue] = useState(defaultValue ?? "");
  const [focused, setFocused] = useState(focus);

  const cancel = () => {
    setValue("");
    setFocused(false);
    onCancel?.();
  };
  const submit = () => {
    cancel();
    onSubmit(value);
  };

  return (
    <Box
      sx={{
        paddingY: 2,
        display: "flex",
        gap: 2,
      }}
    >
      <CommentAvatar name={author} />
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <TextField
          variant="filled"
          placeholder="Ajouter un commentaire"
          sx={{ flex: 1 }}
          value={value}
          focused={focused}
          onChange={(event) => !disabled && setValue(event.target.value)}
          onFocus={() => !disabled && setFocused(true)}
          onClick={onFocus}
        />
        {focused && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 1,
            }}
          >
            {/* <EmojiSelector onClick={(emoji) => setValue(value + emoji)} /> */}
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button onClick={cancel}>Annuler</Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!value}
                onClick={submit}
              >
                Ajouter un commentaire
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CommentInput;
