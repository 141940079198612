import React, { useCallback, useState } from "react";
import { DynamicForm, SubmitButton } from "../../components";
import { useAuth, useAuthApi } from "../../hooks/auth";
import * as Yup from "yup";

const ChangeProfil = () => {
  const [error, setError] = useState("");
  const { user } = useAuth();
  const { updateProfil } = useAuthApi();

  const handleOnSubmit = useCallback(
    async ({ firstName, lastName }) => {
      try {
        await updateProfil({
          ...user,
          firstName,
          lastName,
        });
        console.log("response", "ouloulou");
      } catch (error) {
        setError("auth/change-profil");
      }
    },
    [updateProfil, user]
  );

  return (
    <DynamicForm
      initialValue={user}
      fields={{
        firstName: {
          label: "Prénom",
        },
        lastName: {
          label: "Nom",
        },
        email: {
          label: "Email",
          disabled: true,
        },
      }}
      onSubmit={handleOnSubmit}
      validationSchema={Yup.object({
        firstName: Yup.string().required("ce champ est requis"),
        lastName: Yup.string().required("Ce champ est requis"),
        email: Yup.string()
          .email("Adresse email incorrect")
          .required("Ce champ est requis"),
      })}
      error={error}
    >
      {(form: any) => (
        <SubmitButton
          title="Valider"
          color="primary"
          variant="contained"
          disabled={!form.isValid || form.isSubmitting}
        />
      )}
    </DynamicForm>
  );
};

export default ChangeProfil;
