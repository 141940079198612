import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useParams } from "react-router";
import { PlayerList } from "../../components";
import { useCoachQueries, usePlayersQueries } from "../../hooks";

export const TeamDetail = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const { data } = usePlayersQueries(teamId);
  const { data: coach } = useCoachQueries(teamId);

  if (!data) return null;
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 150,
            height: 150,
            marginRight: 2,
            marginBottom: 2,
            border: "1px solid gray",
          }}
        >
          <img
            style={{ width: 150, height: 150 }}
            src={`https://firebasestorage.googleapis.com/v0/b/supfoot-2e0c1.appspot.com/o/ecussons%2F${data?.team?.id}.png?alt=media`}
            alt={data?.team?.name}
          />
        </Box>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {data?.team?.name}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Entraineur : {coach?.firstname} {coach?.lastname}
          </Typography>
        </Box>
      </Box>

      <Typography gutterBottom variant="h5" component="div">
        Joueurs
      </Typography>
      <PlayerList players={data.players || ([] as any)} />
    </div>
  );
};
