import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@mui/styles";
import FormBody, { FormBodyProps } from "./form-body";
import FormActions, { FormActionsProps } from "./form-actions";
import { Error } from "../error";
const noop = () => {};

const useStyles = makeStyles((theme: any) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "stretch",
  },
  infos: {
    flex: 1,
    marginTop: theme.spacing(2),
  },
}));

type DynamicFormProps = FormBodyProps &
  Partial<FormActionsProps> & {
    validationSchema?: any;
    children?: any;
    onSubmit: (values: any) => void;
    error?: string;
    errorType?: "info" | "error";
    onChange?: Function;
    initialValue?: any;
    header?: React.ReactNode;
  };

export const DynamicForm: React.FC<DynamicFormProps> = ({
  fields,
  validationSchema,
  error,
  errorType,
  children,
  initialValue,
  disabled,
  header,
  submitTitle,
  cancelTitle,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();

  const initialValues = useMemo(() => {
    if (initialValue) return initialValue;
    return Object.keys(fields).reduce(
      (acc, nextField) => ({
        ...acc,
        [nextField]: fields[nextField]?.initialValue,
      }),
      {}
    );
  }, [fields, initialValue]);

  return (
    <div>
      {error && (
        <Error className={classes.infos} errorCode={error} type={errorType} />
      )}
      {header && <div className={classes.infos}>{header}</div>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className={classes.form} noValidate>
          <FormBody fields={fields} disabled={disabled} />
          <div className={classes.section}>
            {typeof children === "function" ? (
              children("hhhh")
            ) : (
              <FormActions
                cancelTitle={cancelTitle || ""}
                submitTitle={submitTitle || ""}
                onCancel={onCancel ?? noop}
              />
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default DynamicForm;
