import { Box, Container, Divider, Toolbar, Typography } from "@mui/material";
import {
  Header,
  Match,
  MatchEvents,
  MatchLineUps,
  MatchStatistics,
  Tabbar,
} from "../../components";
import { useMatchsQueries } from "../../hooks";
import { Route, Routes, useParams } from "react-router-dom";
import { getRoundInFrench, paths } from "../../routes";
import { formatDateFull } from "../../utils.js";
import { useAppContext } from "../../context/app/AppContext";

export const LiveMatch = () => {
  const params = useParams<{ id: string }>();
  const { data } = useMatchsQueries({ id: params.id, season: undefined });
  const { competitions } = useAppContext();
  const match = data?.[0];
  const tabs = [
    paths.liveMatch,
    paths.liveMatchLineUp,
    paths.liveMatchStats,
    paths.liveMatchInfos,
  ].map((path) => ({
    ...path,
    path: path.path.replace(":id", params.id ?? ""),
  }));

  const getPath = (child: string) => {
    const root = paths.liveMatch.path;
    return child.replace(root, "");
  };

  const competition = competitions.find(
    (comp) => +comp.competitionId === match?.league.id
  );
  if (!match) return null;
  return (
    <div>
      <Header>
        <Tabbar tabs={tabs} />
      </Header>
      <Container maxWidth="md" sx={{ padding: 0 }}>
        <Toolbar />
        <Toolbar />
        <Typography
          variant="subtitle1"
          sx={{ textTransform: "capitalize", paddingX: 2 }}
        >
          {competition?.title} - {getRoundInFrench(match.league.round)}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textTransform: "capitalize", paddingX: 2 }}
        >
          {formatDateFull(match.fixture?.date)}
        </Typography>
        <Box
          sx={{
            paddingY: 8,
            paddingX: 3,
            backgroundImage:
              "url(https://media.istockphoto.com/id/472347896/fr/photo/rayures-terrain-de-football.jpg?s=612x612&w=0&k=20&c=1ghbcb4hasm15rrcH_yufLjSttQzDemtj8jAoRO6C_k=)",
          }}
        >
          <Box sx={{ maxWidth: 600, margin: "auto" }}>
            <Match {...match} isCup={competition?.isCup} enableDetails />
          </Box>
        </Box>
      </Container>

      <Container maxWidth="md" sx={{ minHeight: "100Vh", padding: 2 }}>
        <Divider />
        <Box sx={{ marginTop: 3 }}>
          <Routes>
            <Route
              path={getPath(paths.liveMatch.path)}
              element={<MatchEvents events={match.events || []} />}
            />
            <Route
              path={getPath(paths.liveMatchLineUp.path)}
              element={<MatchLineUps lineups={match.lineups || []} />}
            />
            <Route
              path={getPath(paths.liveMatchStats.path)}
              element={<MatchStatistics statistics={match.statistics || []} />}
            />
            <Route
              path={getPath(paths.liveMatchInfos.path)}
              element={
                <div>
                  <Box sx={{ marginTop: 3 }}>
                    <Typography variant="body2">
                      Stade: {match?.fixture?.venue?.name}{" "}
                      <strong>{match?.fixture.venue.city}</strong>
                    </Typography>
                    <Typography variant="body2">
                      Arbitre: {match?.fixture.referee}
                    </Typography>
                  </Box>
                </div>
              }
            />
          </Routes>
        </Box>
      </Container>
    </div>
  );
};

export default LiveMatch;
