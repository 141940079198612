import React, { useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Sidebar } from "../../components";
import { useRoutes } from "../../hooks/routes";
import ChangeProfil from "./change-profil";
import ChangePassword from "./change-password";
import Button from "@mui/material/Button";
import ArrowLeft from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    flex: 1,
  },
  sidebar: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 250,
    },
    textAlign: "left",
  },
}));

export const Profil = () => {
  const classes = useStyles();
  const { currentPath, navigate, paths } = useRoutes();
  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <Sidebar />
      </div>
      <div className={classes.content}>
        <Typography component="h2" variant="h4" align="center">
          {currentPath?.label}
        </Typography>
        <Routes>
          <Route path="password" element={<ChangePassword />} />
          <Route path="/" element={<ChangeProfil />} />
        </Routes>
      </div>
    </div>
  );
};

export default Profil;
