import { useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import {
  Articlesocial,
  Image,
  PreventAuthModal,
  PreventAuthModalTypes,
} from "../../components";
import { Article } from "types";
import { Rte } from "../../components/forms/rte-field";
import { ArticleDate } from "../../components/articles/article-date";
import { useDocMutation, useDocQuery } from "../../hooks/queries";
import { getArticleDescription, useAuth } from "../../hooks";
import { useQueryClient } from "react-query";
import Comments from "../../components/comments/comments";

type ArticleParams = {
  articleId: string;
};

export const ViewArticle = ({ }) => {
  const { articleId } = useParams<ArticleParams>();
  const { data: article } = useDocQuery<Article>("articles", articleId ?? "");
  console.log('article', article)
  const { mutateAsync } = useDocMutation("articles", articleId ?? "", {
    merge: true,
  });
  const description = getArticleDescription(article?.content ?? "");
  const { user, isLoggedIn } = useAuth();
  const queryclient = useQueryClient();
  const userLiked = user?.id ? article?.liked?.[user?.id] : false;
  const { data } = useDocQuery<{ favourites: string[] }>(
    "users",
    user?.id ?? "rr",
    {
      enabled: !!user?.id,
    }
  );
  const { mutateAsync: onFavourite } = useDocMutation(
    "users",
    user?.id ?? "xx"
  );
  const [modalType, setModalType] = useState<PreventAuthModalTypes | null>(null);
  const isFavourite = data?.favourites?.includes(articleId ?? "");
  console.log("favourites", isFavourite);
  const handleOnLike = () => {
    if (!isLoggedIn) {
      setModalType(PreventAuthModalTypes.like);
      return;
    }
    try {
      if (user && user.id) {
        const liked = {
          ...(article?.liked || {}),
          [user?.id]: !userLiked,
        };
        queryclient.setQueryData(["articles", articleId], {
          ...article,
          liked,
        });
        mutateAsync({ liked });
      }
    } catch (error) { }
  };

  console.log('article', article)

  const handleOnFavourite = () => {
    if (!isLoggedIn) {
      setModalType(PreventAuthModalTypes.bookmark);
      return;
    }

    const favourites = isFavourite
      ? data?.favourites?.filter((f) => f !== articleId)
      : [...(data?.favourites || []), articleId];
    try {
      queryclient.setQueryData(["users", user?.id], {
        favourites,
      });
      queryclient.invalidateQueries(["myList", user?.id ?? ""])
      onFavourite({ favourites });
    } catch (e) { }
  };

  const handleOnFocus = () => {
    if (!isLoggedIn) {
      setModalType(PreventAuthModalTypes.comment);
      return;
    }
  };

  if (!article) return null;

  return (
    <Box>
      {!!modalType && (
        <PreventAuthModal
          open={!!modalType}
          type={modalType}
          onClose={() => setModalType(null)}
        />
      )}
      <MetaTags>
        <title>{article.title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={article.title} />
        <meta property="og:image" content={article.image} />
      </MetaTags>
      {!!article && (
        <Box>
          <Chip
            size="small"
            label={article.competition ?? "Foot"}
            onClick={() => { }}
          />
          <Typography sx={{ marginTop: 1 }} fontWeight="bold" variant="h4">
            {article?.title}
          </Typography>
          <ArticleDate created={article.created} />
          <Box sx={{ paddingY: 2 }}>
            <Image src={article?.image} alt={article?.title} />
          </Box>
          <Articlesocial
            title={article.title}
            liked={userLiked}
            isFavourite={isFavourite}
            onLike={handleOnLike}
            onComment={() => { }}
            onFavourite={handleOnFavourite}
          />
          <Rte value={article?.content} onChange={() => { }} label="" readOnly />
          <Comments articleId={articleId!} onFocus={handleOnFocus} />
        </Box>
      )}
    </Box>
  );
};
