import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { AuthBox } from "../auth-box";
import { Search } from "../search";
import { useRoutes } from "../../hooks";

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  right: {
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  image: {
    cursor: "pointer",
  },
}));

type HeaderProps = {
  enableMenu?: boolean;
  onMenuClick?: () => void;
};

export function AdminHeader({ enableMenu, onMenuClick }: HeaderProps) {
  const classes = useStyles();
  const { navigate, paths } = useRoutes();

  const navigateToHome = navigate(paths.home.path);

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <div className={classes.right}>
          {enableMenu && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={onMenuClick}
            >
              <MenuIcon />
            </IconButton>
          )}
           <img
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="logo"
            onClick={navigateToHome}
          />
        </div>
        <AuthBox />
      </Toolbar>
    </AppBar>
  );
}

export default AdminHeader;
