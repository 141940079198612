import React from "react";
import { Crud } from "../../components";
import * as Yup from "yup";
import { useCollectionQuery } from "../../hooks/queries";

const ArticlesCrud = () => {
  const { data: competitions = [] } = useCollectionQuery<{ title: string }>(
    "competitions"
  );
  const { data: values = [] } = useCollectionQuery<{ title: string }>("tags");

  const tags = [...competitions, ...values];
  return (
    <Crud
      name="articles"
      initialValues={{
        title: "",
        content: "",
        image: "",
        published: false,
        competition: "",
      }}
      fields={{
        title: {
          type: "text",
          label: "Titre",
          required: true,
        },
        image: {
          type: "text",
          label: "Image",
          required: true,
        },
        content: {
          label: "Contenu",
          type: "rte",
        },
        competition: {
          label: "Competition",
          type: "select",
          options: tags.map((compet) => ({
            label: compet.title,
            value: compet.title,
          })),
        },
        published: {
          label: "Publié",
          type: "checkbox",
        },
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Ce champ est requis"),
      })}
      columns={[
        { field: "title", headerName: "Title", width: 500 },

        {
          field: "published",
          headerName: "Publié",
          valueFormatter: ({ value }: any) => (value ? "OUI" : "NON"),
        },
      ]}
    />
  );
};

export default ArticlesCrud;
