import React, { useState } from "react";
import { MatchsRound, SelectCompetition } from "../../components";
import { Box } from "@mui/material";
import { Competition } from "types";

export type CompetitionRoundParams = {
  round: string;
  competition?: Competition;
};

type MatchCompetitionRoundProps = {
  onChange: (params: CompetitionRoundParams) => void;
};

export const MatchCompetitionRound: React.FC<MatchCompetitionRoundProps> = ({
  onChange,
}) => {
  const [competition, setCompetition] = useState<Competition>();
  const [round, setRound] = useState("");

  const handleOnChange = (round) => {
    setRound(round);
    onChange({ competition, round });
  };

  return (
    <Box sx={{ display: "flex", gap: 2, marginBottom: 3 }}>
      <SelectCompetition
        selectCompetitionId={competition?.id as any ?? ""}
        onChange={setCompetition}
      />
      {competition && (
        <MatchsRound
          competition={competition}
          onChange={handleOnChange}
          selectedRound={round}
        />
      )}
    </Box>
  );
};
