import React from "react";
import { Box, styled } from "@mui/material";

const CustomImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
}));

type ImageProps = {
  alt?: string;
  src?: string;
  sx?: object;
};

export const Image: React.FC<ImageProps> = ({ sx, ...imageProps }) => {
  return (
    <Box
      sx={{
        paddingBottom: "56.25%",
        position: "relative",
        backgroundColor: "#ccc"
       
      }}
    >
      <CustomImage {...imageProps} />
    </Box>
  );
};
