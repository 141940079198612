import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { Image } from "../../image";
import { Article } from "types";
import { ArticleDetail } from "../article-detail";
import { ArticleDate } from "../article-date";

type ArticleItemProps = Article & {
  display?: "column" | "row";
  sx?: object;
  onClick?: (id?: string) => void;
};

export const ArticleItem: React.FC<ArticleItemProps> = ({
  id,
  title,
  image,
  competition,
  created,
  sx = {},
  display = "row",
  onClick,
}) => {
  return (
    <Link
      sx={{
        display: "block",
        textDecoration: "none",
        paddingY: 2,
        color: "text.primary",
        ...sx,
      }}
      component={RouterLink}
      to={`/articles/${id}`}
      onClick={(event) => {
        event?.preventDefault();
        onClick?.(id);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: display,
          gap: 2,
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            width: display === "row" ? { xs: 120, md: 200 } : "100%",

            flexShrink: 0,
          }}
        >
          <Image src={image} alt={title} />
        </Box>

        <ArticleDetail
          title={title}
          competition={competition}
          display={display}
        />
      </Box>
      <ArticleDate created={created} />
    </Link>
  );
};
