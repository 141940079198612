import { Box, Button, Container, Typography } from "@mui/material"

type InfoProps = {
    title: string;
    icon?: React.ReactElement,
    primaryTitle?: string;
    secondaryTitle?: string;
    onPrimaryClick?: () => void;
    onSecondaryClik?: () => void;
}


export const Info: React.FC<InfoProps> = ({ title, icon, primaryTitle, secondaryTitle, onPrimaryClick, onSecondaryClik }) => {
    return (
        <Container maxWidth="sm" sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: 2}}>
            {icon}
            <Typography>
                {title}
            </Typography>
            {!!primaryTitle && <Button
                variant="contained"
                onClick={onPrimaryClick}
                sx={{ alignSelf: "stretch" }}
            >
                {primaryTitle}
            </Button>}
            {!!secondaryTitle &&
                <Button
                    color="inherit"
                    onClick={onSecondaryClik}
                    sx={{ alignSelf: "stretch" }}>
                    {secondaryTitle}
                </Button>}
        </Container>
    )

}