import React from "react";
import { Crud } from "../../components";
import * as Yup from "yup";

const TagsCrud = () => {
  return (
    <Crud
      name="tags"
      initialValues={{
        title: "",
      }}
      fields={{
        title: {
          label: "Titre",
          required: true,
        },
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Ce champ est requis"),
      })}
      columns={[{ field: "title", headerName: "Title", width: 500 }]}
    />
  );
};

export default TagsCrud;
