import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { Match as MatchType } from "types";
import { TeamDisplay } from "../teams";
import { MatchScore } from "./match-score";
import { MatchScorers } from "./match-scorers";
import { MatchPenalties } from "./match-penalties";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "#e0e0e0",
  height: 75,
  paddingX: theme.spacing(2),
  border: "0.5px solid #bdbdbd",
}));

type MatchProps = MatchType & {
  published?: boolean;
  enableDetails?: boolean;
  isCup?: boolean;
  useProxy?:boolean;
  onClick?: () => void;
};

export const Match: React.FC<MatchProps> = ({
  teams,
  fixture,
  goals,
  score,
  published,
  events,
  enableDetails,
  isCup,
  useProxy,
  onClick,
}) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        background: enableDetails ? "#fafafa" : undefined,
      }}
      onClick={onClick}
    >
      <Container sx={{ paddingX: 1, height: published ? 100 : 50 }}>
        <TeamDisplay
          id={teams.home.id}
          logo={teams.home.logo}
          name={teams.home.name}
          published={published}
          horizontalRatio={isCup}
          useProxy={useProxy}
          sx={{color: "#000"}}
        />
        <MatchScore
          home={goals.home}
          away={goals.away}
          date={fixture.date}
          status={fixture.status.long}
          published={published}
        />

        <TeamDisplay
          id={teams.away.id}
          logo={teams.away.logo}
          name={teams.away.name}
          published={published}
          horizontalRatio={isCup}
          useProxy={useProxy}
          sx={{ flexDirection: "row-reverse", color: "#000" }}          
        />
      </Container>
      {fixture.status.elapsed && !["FT", "PEN"].includes(fixture.status.short) && (
        <Typography sx={{ paddingTop: 1, color: "#b71c1c" }} align="center">
          {fixture.status.short === "HT" ? "MT" : fixture.status.elapsed}"
        </Typography>
      )}
      {fixture.status.elapsed && ["PEN"].includes(fixture.status.short) && (
        <Typography variant="body2" sx={{ paddingTop: 1 }} align="center">
          (t.a.b {score?.penalty.home} - {score?.penalty.away})
        </Typography>
      )}

      {enableDetails && (
        <Box sx={{ display: "flex", color: "white", background: "#fafafa" }}>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <MatchScorers
              events={events ?? []}
              teamName={teams.home.name}
              alignement="start"
            />
          </Box>
          <Box sx={{ width: 50 }} />
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
            <MatchScorers
              events={events ?? []}
              teamName={teams.away.name}
              alignement="end"
            />
          </Box>
        </Box>
      )}
      {enableDetails && (
        <Box sx={{ display: "flex", color: "white", background: "#fafafa" }}>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <MatchPenalties
              events={events ?? []}
              teamName={teams.home.name}
              alignement="start"
            />
          </Box>
          <Box sx={{ width: 50 }} />
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
            <MatchPenalties
              events={events ?? []}
              teamName={teams.away.name}
              alignement="end"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
