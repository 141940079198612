import { useCompetitions, useLocalTeamsQueries } from "../../hooks";
import { createContext, PropsWithChildren, useContext } from "react"
import { Competition, Team } from "types";


type AppContextState = {
    competitions: Competition[];
    localTeams: Record<string, Team>

}

export const AppContext = createContext<AppContextState>({
    competitions: [],
    localTeams: {}
});


export const AppProvider = ({ children }: PropsWithChildren<{}>) => {

    const { data: competitions = [] } = useCompetitions();
    const { data: localTeams = {} } = useLocalTeamsQueries();


    return (
        <AppContext.Provider value={{ competitions, localTeams }}>{children}</AppContext.Provider>
    )

}

export const useAppContext = ()=>{

    return useContext(AppContext); 
}