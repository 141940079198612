import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Chip, useMediaQuery, useTheme } from "@mui/material";

const getVariant = (isDesktop: boolean, display: string) => {
  if (display === "column") {
    return isDesktop ? "h4" : "h6";
  }
  return isDesktop ? "h6" : "subtitle2";
};

type ArticleDetailProps = {
  display?: "column" | "row";
  title: string;
  competition?: string;
};

export const ArticleDetail: React.FC<ArticleDetailProps> = ({
  title,
  competition,
  display = "row",
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const variant = getVariant(matches, display);

  return (
    <Box>
      <Chip size="small" label={competition ?? "Foot"} clickable={false} />
      <Typography
        variant={variant}
        sx={{ fontWeight: "bold", marginTop: 1 }}
      >
        {title}
      </Typography>
    </Box>
  );
};
