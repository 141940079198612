import { Box, Typography, styled } from "@mui/material";
import React from "react";

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== "live",
})<{
  live: boolean;
}>(({ live }) => ({
  width: 100,
  height: "100%",
  backgroundColor: live ? "#212121" : "#b71c1c",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  flex: "0 0 100px",
}));

const formatDate = (date: string, isPublished= false) => {
  return new Intl.DateTimeFormat("default", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: isPublished ? 'Europe/London' : undefined
  })
    .format(new Date(date))
    .replace(",", " ");
};

type TeamScoreProps = {
  date: string;
  home: string;
  away: string;
  status: string;
  published?: boolean;
};

export const MatchScore: React.FC<TeamScoreProps> = ({
  home,
  away,
  date,
  status,
  published,
}) => {
  const live = ["Match Finished", "Not Started"].includes(status);
  const display = home !== null ? `${home} - ${away}` : formatDate(date, published);
  return (
    <Container live={live} sx={{ flex: published ? "0 0 100px" : "0 0 50px" }}>
      <Typography variant={published ? "h5" : "body2"}>{display}</Typography>
    </Container>
  );
};
