import {
  Box,
  Button,
  ButtonGroup,
  Container,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const getDates = (past = false) => {
  return new Array(4).fill(1).map((_, index) => {
    const date = new Date();
    const day = index + 1;
    const step = past ? -1 * day : day;

    date.setDate(today.getDate() + step);
    return date;
  });
};

const today = new Date();
const pasts = getDates(true).reverse();
const nexts = getDates();
const allDates = [...pasts, today, ...nexts];

const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

const getFormattedDate = (date) => {
  return new Intl.DateTimeFormat("fr-FR", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  })
    .format(date)
    .split("/")
    .reverse()
    .join("-");
};

type MatchFilterProps = {
  onChange?: (date) => void;
};

export type DateFilter = {
  today: boolean;
  date: string;
};

export const MatchFilter: React.FC<MatchFilterProps> = ({ onChange }) => {
  const [currentDate, setCurrentDate] = useState(4);

  const handleOnChange = (_event, index) => {
    const date = allDates[index];
    console.log("date", date);
    setCurrentDate(index);
    const selectedDate = {
      date: getFormattedDate(date),
      today: date === today,
    };
    onChange?.(selectedDate);
  };

  useEffect(() => {
    onChange?.({ date: getFormattedDate(today), today: true });
  }, []);

  return (
    <Container maxWidth="md">
      <Tabs
        value={currentDate}
        onChange={handleOnChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        {allDates.map((date, index) => (
          <Tab
            sx={{ minWidth: 10}}
            key={index}
            label={
              <Box>
                <Typography variant="caption" fontSize={12}>
                  {index === 4 ? "Auj" : days[date.getDay()]}
                </Typography>
                <br/>
                <Typography variant="caption">{date?.getDate()}</Typography>
              </Box>
            }
            value={index}
          />
        ))}
      </Tabs>
    </Container>
  );
};
