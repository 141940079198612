import { useCreateMutation } from "../../hooks/queries";
import React from "react";
import Forms, { BaseFormsProps } from "./forms";

export const Add: React.FC<BaseFormsProps> = (props) => {
  const { mutateAsync: create } = useCreateMutation(props.name);

  const handleOnSubmit = (values) => {
    return create({ ...values, created: new Date().getTime() });
  };
  return <Forms onSubmit={handleOnSubmit} {...props} />;
};
