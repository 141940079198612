import { Typography } from "@mui/material";
import React from "react";

const getRemainingTime = (createdTime) => {
  if (!createdTime) return;
  const currentTime = new Date().getTime();
  const elapsed = currentTime - createdTime;
  
  if (elapsed > 24 * 60 * 60 * 1000) {
    return (
      "Publié le " + new Intl.DateTimeFormat("fr").format(new Date(createdTime))
    );
  }
  if (elapsed < 60 * 60 * 1000) {
    return `Il y a ${Math.ceil(elapsed / ( 60 * 1000))} min`;
  }
  
    return `Il y a ${Math.ceil(elapsed / (  60 * 60 * 1000))} h`;
  
};

export const ArticleDate: React.FC<{ created: number; author?: string }> = ({
  created,
  author,
}) => {
  return (
    <Typography color="text.secondary" variant="caption">
      {getRemainingTime(created)} {author ? `par ${author}` : ""}
    </Typography>
  );
};
