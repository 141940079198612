import React, { useRef, useState, FunctionComponent, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

type TMyCardData = {
  text?: string;
};

type TAnchor = HTMLElement | null;

interface IMyCardPopoverProps {
  anchor: TAnchor;
  onSubmit: (data: TMyCardData, insert: boolean) => void;
}

type TMyCardPopoverState = {
  anchor: TAnchor;
  isCancelled: boolean;
};

const cardPopverStyles = makeStyles({
  root: {
    padding: 10,
    maxWidth: 350,
  },
  textField: {
    width: "100%",
  },
});

export const InsertIframe: FunctionComponent<IMyCardPopoverProps> = (props) => {
  const classes = cardPopverStyles(props);
  const [state, setState] = useState<TMyCardPopoverState>({
    anchor: null,
    isCancelled: false,
  });
  const [data, setData] = useState<TMyCardData>({});

  useEffect(() => {
    setState({
      anchor: props.anchor,
      isCancelled: false,
    });
  }, [props.anchor]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const textFieldProps = {
    className: classes.textField,
    onChange: handleChange,
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Popover
      anchorEl={state.anchor}
      open={state.anchor !== null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <TextField
            {...textFieldProps}
            autoFocus={true}
            label="Search term"
            name="text"
            placeholder="Type anything here..."
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: true,
              });
            }}
          >
            <CloseIcon />
          </Button>
          <Button
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: false,
              });
              props.onSubmit(data, !state.isCancelled);
            }}
          >
            <DoneIcon />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export const IframeView = (props) => {
  const { blockProps } = props;
  return (
    <Box
      sx={{ display: "flex", paddingY: 3, justifyContent: "center" }}
      dangerouslySetInnerHTML={{ __html: blockProps?.text }}
    ></Box>
  );
};

export default InsertIframe;
