import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type AlertDialogProps = {
  open: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  onConfirm: () => void;
  cancelTitle?: string;
  confirmTitle?: string;
};

export const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  description,
  title,
  cancelTitle,
  confirmTitle,
  onClose,
  onConfirm,
}) => (
  <div>
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelTitle}</Button>
        <Button onClick={onConfirm} autoFocus>
          {confirmTitle}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);
