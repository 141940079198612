import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import React from "react";
import { MatchEvent } from "types";

const getDetail = (detail: string) => {
  if (detail === "Yellow Card") {
    return "Carton Jaune";
  }
  if (detail.includes("Substitution")) {
    return "Remplacement";
  }
  if (detail.includes("Missed Penalty")) return "Raté";
  if (detail.includes("Penalty") || detail.includes("Normal Goal")) {
    return "But";
  }
  if (detail.includes("Goal confirmed")) {
    return "VAR - But Confirmé";
  }

  if (detail.includes("Own Goal")) {
    return "C.S.C but";
  }
  return detail;
};

const EventItem: React.FC<{ event: MatchEvent }> = ({
  event: { team, type, time, detail, player, assist, comments },
}) => {
  const moment = comments === "Penalty Shootout" ? "T.A.B" : `${time.elapsed}"`;
  const eventDetail = getDetail(detail);
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt="Remy Sharp" src={team.logo} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <strong>{moment}</strong> - {eventDetail}
          </>
        }
        secondary={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {type.includes("subs") && (
              <>
                <Typography component="span" variant="body2">
                  {assist.name}
                </Typography>
                <SwapHorizIcon />
              </>
            )}
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
            >
              {player.name}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};

export const MatchEvents: React.FC<{ events: MatchEvent[] }> = ({ events }) => {
  return (
    <List>
      {[...events].reverse().map((event, index) => (
        <React.Fragment>
          {index !== 0 && <Divider variant="inset" component="li" />}
          <EventItem event={event} />
        </React.Fragment>
      ))}
    </List>
  );
};
