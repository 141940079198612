import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Comment } from "types";
import CommentInput from "./comment-input";
import CommentItem from "./comment";
import {
  update,
  useCollectionQuery,
  useCreateMutation,
  remove,
} from "../../hooks/queries";
import { useAuth } from "../../hooks";
import { useQueryClient } from "react-query";

type CommentsProps = {
  articleId: string;
  onFocus?: () => void;
};

const Comments: React.FC<CommentsProps> = ({ articleId, onFocus }) => {
  const { user, isLoggedIn } = useAuth();
  const author = user ? `${user?.firstName} ${user?.lastName}` : undefined;
  const [showAll, setShowAll] = useState(false);
  const queryClient = useQueryClient();

  const { data = [], refetch } = useCollectionQuery<Comment>(
    "comments",
    {
      orderBy: { key: "created", order: "asc" },
      where: [["articleId", "==", articleId]],
    },
    {
      key: ["comments", articleId],
    }
  );

  const { mutateAsync: add } = useCreateMutation("comments");

  const comments = showAll ? data : data.slice(0, 1);
  const addComment = async (text: string, parentId?: string) => {
    const comment = {
      author: user,
      text,
      articleId,
      parentId: parentId ?? "",
      created: new Date().getTime(),
    };

    await add(comment);
    setShowAll(true);
    refetch();
  };

  const handleOnEdit = async (comment: Comment) => {
    await update("comments", comment.id!, comment);
    refetch();
  };

  const handleOnDelete = async (commentId: Comment["id"]) => {
    await remove("comments", commentId!);
    refetch();
  };

  return (
    <Box>
      <Typography variant="h6">Commentaires</Typography>
      <Divider sx={{ marginY: 2 }} />
      <CommentInput
        onSubmit={addComment}
        author={author}
        onFocus={onFocus}
        disabled={!isLoggedIn}
      />
      {comments
        .filter((c) => !c.parentId)
        .map((comment) => (
          <Box key={comment.id}>
            <CommentItem
              comment={comment}
              author={author}
              parentId={comment.id}
              canEdit={comment?.author?.id === user?.id}
              onReply={addComment}
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
            >
              {comments
                .filter((c) => c.parentId == comment.id)
                .map((c) => (
                  <CommentItem
                    key={c.id}
                    comment={c}
                    author={author}
                    parentId={comment.id}
                    canEdit={c?.author?.id === user?.id}
                    onReply={addComment}
                    onEdit={handleOnEdit}
                    onDelete={handleOnDelete}
                  />
                ))}
            </CommentItem>
          </Box>
        ))}
      {!showAll && !!data.length && (
        <Button
          sx={{ width: "100%" }}
          color="primary"
          onClick={() => setShowAll(true)}
        >
          Afficher les commentaires
        </Button>
      )}
    </Box>
  );
};

export default Comments;
