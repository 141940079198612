import { useCallback, useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  getFirestore,
  CollectionReference,
  DocumentData,
  Query,
  WhereFilterOp,
  where,
  limit,
  query,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
  DocumentReference,
} from "firebase/firestore";

type Condition = [any, WhereFilterOp, string | string[]];

const getList = <T>(collection: CollectionReference<DocumentData>) => (
  conditions: Condition[] = [],
  max?: number
) => {
  let myCollection = collection as Query;
  conditions.forEach(
    ([field, condition, value]) =>
      (myCollection = query(myCollection, where(field, condition, value)))
  );
  if (max) {
    myCollection = query(myCollection, limit(max));
  }
  return useCollectionData<T>(getDocs(myCollection), { idField: "id" });
};
const getDataById = <T>(collection: any) => (id: string) => [null, false, null];

export const useCrud = <T>(key: string) => {
  const db = getFirestore();

  const create = useCallback((data: T) => {
    return addDoc(collection(db, key), {
      ...data,
      created: new Date().getTime(),
    });
  }, []);

  const update = useCallback(
    (id: string, data: T) => {
      return updateDoc(doc(db, key, id), {
        ...data,
       // created: new Date().getTime(),
      });
    },
    [collection]
  );

  const remove = useCallback(
    (id: string) => {
      return deleteDoc(doc(db, key, id));
    },
    [collection]
  );

  return {
    create,
    update,
    remove,
    list: getList<T>(collection(db, key)),
    getById: getDataById<T>(db),
  };
};

export const useDocs = <T extends { id?: string }>(
  key: string
): [T[], boolean, any] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<any>();

  const fetchDocs = useCallback(async () => {
    try {
      const dataValues = new Array<T>();
      const query = collection(getFirestore(), key) as Query<T>;
      const docs = await getDocs<T>(query);
      docs.forEach((doc) => {
        dataValues.push({ id: doc.id, ...doc.data() });
      });
      setData(dataValues);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [key]);

  useEffect(() => {
    fetchDocs();
  }, [fetchDocs]);

  return [data, loading, error];
};

export const useDocsSnapshot = <T extends { id?: string }>(
  key: string
): [T[], boolean, any] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<any>();
  let unsubscribe;

  useEffect(() => {
    const query = collection(getFirestore(), key) as Query<T>;
    unsubscribe = onSnapshot(query, (snapshot) => {
      const dataValues = new Array<T>();
      snapshot.forEach((doc) => {
        dataValues.push({ ...doc.data(), id: doc.id });
      });
      setData(dataValues);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return [data, loading, error];
};

export const useDoc = <T extends { id?: string }>(
  key: string,
  id: string
): [T | null, boolean, any] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any>();

  const fetchDoc = useCallback(async () => {
    try {
      const query = doc(getFirestore(), key, id) as DocumentReference<T>;
      const myDoc = await getDoc<T>(query);
      if (myDoc.exists()) {
        setData({ id: myDoc.id, ...myDoc.data() });
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [key]);

  useEffect(() => {
    fetchDoc();
  }, [fetchDoc]);

  return [data, loading, error];
};
