import { Box, styled, Typography, SxProps } from "@mui/material";
import { useLocalTeamsQueries } from "../../hooks";
import React from "react";
import { Team } from "types";
import { TeamLogo } from "./team-logo";
import { useAppContext } from "../../context/app/AppContext";

const Container = styled(Box)({
  display: "flex",
  flex: 1,
  height: "100%",
  alignItems: "center",
  paddingX: 1,
});

type TeamDisplayProps = Pick<Team, "id" | "name" | "logo"> & {
  sx?: SxProps;
  published?: boolean;
  horizontalRatio?: boolean;
  useProxy?: boolean;
};

const proxyUrl = "http://localhost:8080/";

export const TeamDisplay: React.FC<TeamDisplayProps> = ({
  id,
  name,
  logo,
  sx,
  published,
  horizontalRatio,
  useProxy
}) => {
  const {localTeams} = useAppContext(); 
  const team = localTeams[id?.toString() + ""] 
  const teamLogo = team ? `https://firebasestorage.googleapis.com/v0/b/supfoot-2e0c1.appspot.com/o/teams%2F${id}.png?alt=media` : logo

  const logoUrl = useProxy ? proxyUrl + teamLogo : teamLogo; 
  
  return (
    <Container sx={sx}>
      <TeamLogo
        logo={logoUrl ?? ""}
        alt={name}
        horizontalRatio={horizontalRatio}
        large={published}

      />
      <Typography sx={{ marginX: 1 }} variant={published ? "h4" : "caption"}>
        {team?.name ?? name}
      </Typography>
    </Container>
  );
};
