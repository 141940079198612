import React from "react";
import { TextField, CheckboxWithLabel } from "formik-mui";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import SelectField from "./select-field";
import RteField from "./rte-field"

export const DynamicField = (props: any) => {
  if (props.type === "section") {
    return (
      <Typography variant="h6" component="h2" color="textPrimary">
        {props.label}
      </Typography>
    );
  }
  if (props.type === "custom") {
    return props.component;
  }
  if (props.type === "select") {
    return <SelectField {...props} />;
  }

  if(props.type === "rte"){
    return <RteField {...props} />
  }
  if(props.type === "checkbox"){
    return <Field type="checkbox" name={props.name} component={CheckboxWithLabel} Label={{ label: props.label, labelPlacement:"start" }} />;
  }
  return <Field {...props} variant="outlined" component={TextField} />;
};

export default DynamicField;
