import React from "react";
import { DynamicForm, FieldType } from "../../components";
import { useRoutes } from "../../hooks";
import { useQueryClient } from "react-query";

export type BaseFormsProps = {
  name: string;
  initialValues?: any;
  fields: { [key: string]: FieldType };
  validationSchema: any;
  transform?: (values) => any;
};

export type FormsProps = BaseFormsProps & {
  onSubmit: (values) => void;
};

export const Forms: React.FC<FormsProps> = ({
  name,
  initialValues,
  fields,
  validationSchema,
  transform,
  onSubmit,
}) => {
  const queryClient = useQueryClient();
  const { navigate } = useRoutes();
  const navigateToList = navigate("/admin/" + name);

  const handleOnSubmit = async (values) => {
    try {
      const transformValues = transform?.(values) ?? values;
      await onSubmit(transformValues);
      queryClient.invalidateQueries([name]);
      navigateToList();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <DynamicForm
      initialValue={initialValues}
      fields={fields}
      validationSchema={validationSchema}
      submitTitle="Valider"
      cancelTitle="Annuler"
      onSubmit={handleOnSubmit}
      onCancel={navigateToList}
    />
  );
};

export default Forms;
