import React from "react";

type TeamLogoProps = {
  logo: string;
  alt?: string;
  large?: boolean;
  horizontalRatio?: Boolean;
};

export const TeamLogo: React.FC<TeamLogoProps> = ({
  logo,
  alt,
  large,
  horizontalRatio,
}) => {
  let width = large ? 50 : 30;
  let height = horizontalRatio ? width * 0.7 : width;
  return <img src={logo} alt={alt} width={width} height={height} />;
};
