import React, { useCallback } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import { makeStyles, withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";

const adminRoutes = [
  paths.articles,
  paths.categories,
  paths.adminCompetitions,
  paths.publications,
  paths.affiche,
];

const useStyles = makeStyles((theme: any) => ({
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    width: 80,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -18,
    top: 10,
  },
}))(Badge);

type MenuItemProps = {
  path: string;
  label: string;
  icon?: React.ReactNode;
  badge?: string;
  onChange: Function;
  className?: string;
};

const MenuItem: React.FC<MenuItemProps> = ({
  path,
  label,
  icon,
  onChange,
  badge,
  className,
}: MenuItemProps) => {
  const navigate = useNavigate();
  const selected = false;
  /*useRouteMatch({
    path,
  });*/

  const handleOnItemClick = useCallback(() => {
    onChange();
    navigate(path);
  }, [navigate, path, onChange]);

  return (
    <ListItem
      key={path}
      className={className}
      onClick={handleOnItemClick}
      selected={!!selected}
      button
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={
          <StyledBadge color="secondary" badgeContent={badge} max={9999}>
            {label}
          </StyledBadge>
        }
      />
    </ListItem>
  );
};

type SidebarMenuProps = {
  onChange: () => void;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  onChange,
}: SidebarMenuProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.toolbar}>Supfoot</div>
      <Divider />
      <List component="nav">
        {adminRoutes.map((route) => (
          <MenuItem
            key={route.path}
            path={route.path}
            label={route.label}
            onChange={onChange as any}
            className={classes.nested}
          />
        ))}
      </List>
    </div>
  );
};

export default SidebarMenu;
