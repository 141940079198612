import { Avatar } from "@mui/material";
import React, { useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";

function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const CommentAvatar: React.FC<{ name?: string }> = ({ name }) => {
  if (name) {
    return <Avatar {...stringAvatar(name)} />;
  }
  return (
    <Avatar>
      <AccountCircle />
    </Avatar>
  );
};
export default CommentAvatar;
