import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Copyright from "@mui/icons-material/CopyrightRounded";
import Link from "@mui/material/Link";
import { useRoutes } from "../../hooks";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
    textAlign:"center"
  },
  content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}));

export const Footer = () => {
  const classNames = useStyles();
  const { navigate, paths } = useRoutes();
  const navigateToContact = navigate(paths.contact.path);
  return (
    <Box sx={{textAlign: "center", padding:2 }}>
      <Typography fontWeight="bold" className={classNames.content} variant="subtitle1">
        <Copyright /> <span className={classNames.title}>Supfoot {new Date().getFullYear()}</span>
      </Typography>
      <Typography className={classNames.content} variant="subtitle1">
        Le football africain dans une dimension supérieure 
      </Typography>
      <Typography variant="caption">
        <Link href="https://www.facebook.com/supfoot" color="inherit">
          Facebook
        </Link>{" "}
        |{" "}
        <Link
          href="https://www.instagram.com/supfoot.officiel"
          color="inherit"
        >
          Instagram
        </Link>{" "}
        |{" "}
        <Link href="https://www.youtube.com/" color="inherit">
          Youtube
        </Link>{" "}
        |{" "}
        <Link href="" color="inherit" onClick={navigateToContact}>
          Nous contacter
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
