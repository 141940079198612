import React from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  crumb: {
    textTransform: 'capitalize',
  },
}));

export const BreadCrumb = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const crumbs = pathname.slice(1).split('/');
  const format = (crumb: string) => {
    return crumb
      .replace('-', ' ');
  };
  return (
    <Typography color="textPrimary">
      {crumbs.map((crumb, index) => (
        <React.Fragment key={crumb}>
          {index < crumbs.length - 1 ? (
            <Link className={classes.crumb}>{format(crumb)}</Link>
          ) : (
            <span className={classes.crumb}>{format(crumb)}</span>
          )}

          {index < crumbs.length - 1 ? '  >  ' : ''}
        </React.Fragment>
      ))}
    </Typography>
  );
};

export default BreadCrumb;
