import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useAuthApi } from "../../hooks";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}));

const Facebook = styled(Button)({
  flex: 1,
  backgroundColor: "#3b5998",
  "&:hover": {
    backgroundColor: "#3b5998",
  },
});

const Google = styled(Button)({
  flex: 1,
  backgroundColor: "#DB4437",
  "&:hover": {
    backgroundColor: "#DB4437",
  },
});

type SocialProps = {
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

export function Social({ onSuccess, onError }: SocialProps) {
  const { continueWithSocial} = useAuthApi();

  const loginWithSocial = useCallback(async (type) => {
    try {
      await continueWithSocial(type);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
    }
  }, [continueWithSocial, onSuccess, onError]);

  return (
    <Container>
      <Facebook
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => loginWithSocial("facebook")}
      >
        Facebook
      </Facebook>
      <Google
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => loginWithSocial("google")}
      >
        Google
      </Google>
    </Container>
  );
}
export default Social;
