import { useState, useCallback, useEffect } from "react";
import { MatchsRound, MatchList } from "../../components";
import Box from "@mui/material/Box";
import {
  useMatchsQueries,
  useRoundQueries,
  useSelectedCompetition,
} from "../../hooks";
import { orderBy, groupBy } from "lodash";
import { formatDateFull } from "../../utils.js";

export const Matchs = () => {
  const [round, setRound] = useState("Direct");
  const competition = useSelectedCompetition();

  const { data: rounds = [] } = useRoundQueries(
    { league: competition?.competitionId!, season: +competition?.season! },
    !!competition
  );
  const { data: currentRounds = [] } = useRoundQueries(
    {
      league: competition?.competitionId!,
      season: competition?.season!,
      current: true,
    },
    !!competition
  );

  const [currentRound] = currentRounds;
  const lastRound = rounds[rounds.length - 1];

  const params = {
    round,
    league: competition?.competitionId,
    season: competition?.season,
  };

  const { data = [] } = useMatchsQueries(params);

  const allMatchs = groupBy(
    orderBy(data, (d) => d.fixture.date),
    (d) => {
      return formatDateFull(d.fixture.date);
    }
  );

  const handleOnChange = useCallback((round: string) => {
    setRound(round);
  }, []);

  useEffect(() => {
    setRound(currentRound ?? lastRound);
  }, [currentRound, lastRound]);

  return (
    <Box>
      {!!competition && (
        <MatchsRound
          competition={competition}
          selectedRound={round}
          onChange={handleOnChange}
        />
      )}
      {Object.keys(allMatchs).map((date) => (
        <Box sx={{ paddingY: 2 }}>
          <MatchList
            subtitle={date}
            matchs={allMatchs[date]}
            round={params?.round}
            isCup={competition?.isCup}
          />
        </Box>
      ))}
    </Box>
  );
};
export default Matchs;
