import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { Competition } from "../../types";
import { useAppContext } from "../../context/app/AppContext";

type SelectCompetionProps = {
  selectCompetitionId: string;
  onChange: (competition: Competition) => void;
};

export const SelectCompetition: React.FC<SelectCompetionProps> = ({
  selectCompetitionId,
  onChange,
}) => {
  const { competitions } = useAppContext();
  const handleChange = (event: SelectChangeEvent) => {
    const competitionId = event.target.value;
    const competition = competitions.find((comp) => (comp.id as any) === competitionId);
    onChange(competition!);
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="select-competition">Competition</InputLabel>
        <Select
          labelId="select-competition"
          label="Compétition"
          placeholder="choisir une compétition"
          value={selectCompetitionId}
          onChange={handleChange}
          autoWidth
        >
          {competitions.map((competition) => (
            <MenuItem key={competition.id} value={competition.id}>
              {competition.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
