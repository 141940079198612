import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import DynamicField from "./dynamic-field";

const useStyles = makeStyles((theme: any) => ({
  input: {
    flex: 1,
  },
  gridItem: {
    display: "flex",
  },
  loading: {
    marginRight: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "stretch",
  },
  actionBtn: {
    flex: 1,
  },
}));

export type FieldType = {
  name?: string;
  type?:
    | "text"
    | "password"
    | "email"
    | "date"
    | "select"
    | "radio"
    | "../../routes"
    | "number"
    | "file"
    | "section"
    | "input-range"
    | "autocomplete"
    | "checkbox"
    | "time"
    | "custom"
    | "rte";
  label: string;
  initialValue?: string | number | Date | boolean | any[];
  required?: boolean;
  disabled?: boolean;
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  options?: any[];
  multiline?: boolean;
  rows?: number;
  onChange?: Function;
  multiple?: boolean;
  value?: any;
  component?: React.ReactNode;
  placeholder?: string;
};

export type FormBodyProps = {
  fields: { [key: string]: FieldType };
  disabled?: boolean;
};

export const FormBody: React.FC<FormBodyProps> = ({ fields, disabled }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {Object.keys(fields).map((fieldName) => (
        <Grid
          item
          xs={12}
          sm={fields[fieldName].grid}
          className={classes.gridItem}
          key={fieldName}
        >
          <DynamicField
            {...fields[fieldName]}
            name={fieldName}
            className={classes.input}
            disabled={disabled || fields[fieldName].disabled}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FormBody;
