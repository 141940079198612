import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100vw",
    minHeight: "100vh",

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: 120,
    textAlign: "center",
    marginBottom: theme.spacing(2),
    img: {
      width: "100%",
      Height: "100%",
    },
  },
  content: {
    marginTop: theme.spacing(2),
    width:"100%"
  },
}));

type AuthContainerProps = {
  title: string;
  children?: React.ReactNode
};

export const AuthContainer = ({ children, title }: AuthContainerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Box sx={{textAlign: "center"}}>
          <img
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/logo-black.png`}
            alt="logo"
          />
        </Box>
        <Typography variant="h4" component="h2" align="center">
          {title}
        </Typography>
        <div className={classes.content}>{children}</div>
      </Container>
    </div>
  );
};

export default AuthContainer;
