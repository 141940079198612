import React, { createContext, useContext, useState } from "react";

const Context = createContext({ darkMode: false, toggle: () => {} });

export const DarkModeProvider: React.FC = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <Context.Provider
      value={{ darkMode, toggle: () => setDarkMode(!darkMode) }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDarkMode = () => {
  return useContext(Context);
};
