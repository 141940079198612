import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getFirestore,
  updateDoc,
} from "firebase/firestore";

type DocumentMutationOptions = {
  merge?: boolean;
};

export const generateId = (key: string) => {
  return doc(collection(getFirestore(), key)).id;
};

export const useDocMutation = <T>(
  key: string,
  subkey: string,
  options?: DocumentMutationOptions
) => {
  const query = doc(getFirestore(), key, subkey) as DocumentReference<T>;
  return useFirestoreDocumentMutation(query, options);
};

export const useCreateMutation = <T>(key: string) => {
  const query = collection(getFirestore(), key) as CollectionReference<T>;
  return useFirestoreCollectionMutation(query);
};

export const useDeleteMutation = (key: string, subkey: string) => {
  const ref = doc(collection(getFirestore(), key), subkey);
  return useFirestoreDocumentDeletion(ref);
};

export const update = (key: string, id: string, data: any) => {
  return updateDoc(doc(getFirestore(), key, id), {
    ...data,

  });
};

export const remove = (key: string, id: string) => {
  return deleteDoc(doc(getFirestore(), key, id));
};
