import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

type LoaderProps = {
    loading?: boolean;
    children?: React.ReactNode;
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
    },
}));

export const Loader: React.FC<LoaderProps> = ({ loading = true, children }: LoaderProps) => {
    const classes = useStyles();
    if (loading) {
        return (
            <div className={classes.root}>
                <CircularProgress />
            </div>
        );
    }

    return <div>{children}</div>;
};
export default Loader;
