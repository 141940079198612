import { Box, Typography } from "@mui/material";
import React from "react";
import { getStatsInFrench } from "../../routes";
import { Statistics } from "../../types";

export const MatchStatistics: React.FC<{ statistics: Statistics[] }> = ({
  statistics,
}) => {
  if (!statistics.length) {
    return <Typography>Pas disponible</Typography>;
  }
  const [home, away] = statistics;
  return (
    <Box>
      {home.statistics.map((stat, index) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" gutterBottom>
            {stat.value ?? 0}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {getStatsInFrench(stat.type)}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {away.statistics[index].value ?? 0}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
