import { useCallback, useEffect, useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword as updatePasswordApi,
  FacebookAuthProvider,
  GoogleAuthProvider,
  updateProfile,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { User } from "../types";

const getProvider = (type: string) => {
  if (type === "facebook") {
    return new FacebookAuthProvider();
  }

  if (type === "google") {
    return new GoogleAuthProvider();
  }
};

export const useAuthApi = () => {
  const auth = getAuth();
  const db = getFirestore();
  const login = useCallback((email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  }, [auth]);

  const register = useCallback((email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  }, [auth]);

  const updateUser = useCallback(
    (infos: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    }) => {
      const infoDoc = doc(db, `users/${infos.id}`);
      return setDoc(infoDoc, infos);
    },
    [ db]
  );

  const forgetPassword = useCallback((email: string) => {
    return sendPasswordResetEmail(auth, email);
  }, [auth]);

  const resetPassword = useCallback((password: string, code: string) => {
    return confirmPasswordReset(auth, code, password);
  }, [auth]);

  const continueWithSocial = useCallback((type: "facebook" | "google") => {
    const provider = getProvider(type);
    if (!provider) return null;
    return signInWithPopup(auth, provider);
  }, [auth]);

  const logout = useCallback(() => {
    return signOut(auth);
  }, [auth]);

  const updateProfil = useCallback(
    (infos) => {
      const { firstName, lastName } = infos;
      if (!auth.currentUser) return Promise.reject();
      return updateProfile(auth.currentUser, {
        displayName: JSON.stringify({ firstName, lastName }),
      }).then(() => updateUser(infos));
    },
    [updateUser, auth]
  );

  const updatePassword = useCallback(
    (email: string, password: string, newPassword: string) => {
      return login(email, password).then(() => {
        if (!auth.currentUser) return Promise.reject();
        return updatePasswordApi(auth.currentUser, newPassword);
      });
    },
    [login, auth]
  );

  return {
    login,
    register,
    updateUser,
    continueWithSocial,
    forgetPassword,
    resetPassword,
    logout,
    updateProfil,
    updatePassword,
  };
};

export const useAuth = () => {
  const error = null;
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const isLoggedIn = !!user;
  console.log("user", user)

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        console.log("displayName", user);
        console.log("user", user)
        const { firstName = "Dr", lastName = "Tapha" }  =user.displayName ? JSON.parse(
          user.displayName ?? ""
        ) : { firstName: "", lastName: "" } ;
        setUser({ id: user.uid, email: user.email ?? "", firstName, lastName });
      }
      setLoading(false);
    });
  }, []);

  return {
    user,
    isLoggedIn,
    loading,
    error,
  };
};
